import config from './Config';
import http from "./Http";
import { ThemeContext } from './Http';
import { Typography } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import LinkIcon from '@material-ui/icons/Link';
import DeviceSummary from "./Monitor/DeviceSummary";
import Alarm from "./Monitor/Alarm";
import CellVoltage from "./Monitor/CellVoltage";
import Temperature from "./Monitor/Temperature";
import Relay from "./Monitor/Relay";
import Switch from "./Monitor/Switch";
import InverterStatus from "./Monitor/InverterStatus";

// const useStyles = makeStyles((theme) => ({
//     rt: {

//     }
// }));

function Monitor(props) {
    const { setTip, deviceID, deviceSN } = props;

    const session = useContext(ThemeContext);

    const [sck, setSck] = useState(null); // WebSocket
    const [summaryData, setSummaryData] = useState(null);
    const [alarmData, setAlarmData] = useState(null);
    const [cellVoltageData, setCellVoltageData] = useState(null);
    const [temperatureData, setTemperatureData] = useState(null);
    const [switchData, setSwitchData] = useState(null);
    const [relayData, setRelayData] = useState(null);
    const [inverterStatusData, setInverterStatusData] = useState(null);


    useEffect(() => {
        if (sck) {
            return;
        }

        //console.log("Monitor===>", "useEffect[sck]")

        const wss = new WebSocket(config.wssUrl + "?Token=" + http.token);
        wss.addEventListener('open', handleWssOpen);
        wss.addEventListener('message', handleWssMessage);
        wss.addEventListener('close', handleWssClose);
        wss.addEventListener('error', handleWssError);

        // return () => {
        //     wss.close();
        // }
    }, [sck]);

    useEffect(() => {
        if (!sck || !deviceSN) {
            return;
        }

        sck.send(JSON.stringify({
            "Type": 1,
            "ID": "99999",
            "DeviceSN": deviceSN,
            "Action": 1,
        }));

        setSummaryData(null);
        setAlarmData(null);
        setRelayData(null);
        setSwitchData(null);
        setCellVoltageData(null);
        setTemperatureData(null);
        setInverterStatusData(null);

        return () => {
            sck.send(JSON.stringify({
                "Type": 1,
                "ID": "99999",
                "DeviceSN": deviceSN,
                "Action": 0,
            }));
        }
    }, [deviceSN])

    const handleWssOpen = (evt) => {
        const wss = evt.currentTarget;
        setSck(wss);

        if (deviceSN) {
            wss.send(JSON.stringify({
                "Type": 1,
                "ID": "99999",
                "DeviceSN": deviceSN,
                "Action": 1,
            }));
        }

        evt.currentTarget.tmrPing = setInterval(() => {
            if (!wss.tmrPing) {
                return;
            }

            wss.send("Ping");
        }, 30000);
    }

    const handleWssMessage = (evt) => {
        //console.log("Wss Message", evt, evt.data);

        const d = evt.data;
        if (d == "Pong") {
            return;
        }

        //
        try {
            const data = JSON.parse(d);
            if (!data || data["Type"] != 'REALTIME') {
                return;
            }

            const x = JSON.parse(data["X"])

            switch (data["MsgID"]) {
                case 0x1000:
                    setSummaryData(x);
                    break;
                case 0x1001:
                    setAlarmData(x);
                    break;
                case 0x1002:
                    setRelayData(x);
                    break;
                case 0x1003:
                    setSwitchData(x);
                    break;
                case 0x1005:
                    setCellVoltageData(x);
                    break;
                case 0x1021:
                    setTemperatureData(x);
                    break;
                case 0x1040:
                    setInverterStatusData(x);
                    break;
            }
        } catch (e) {
            console.log("[Monitor]", e);
        }
    }

    const handleWssClose = (evt) => {
        console.log("Wss Close", evt);

        let wss = evt.currentTarget;
        wss.removeEventListener('open', handleWssOpen);
        wss.removeEventListener('message', handleWssMessage);
        wss.removeEventListener('close', handleWssClose);
        wss.removeEventListener('error', handleWssError);
        if (wss.tmrPing) {
            clearInterval(wss.tmrPing);
            wss.tmrPing = null;
        }

        setSck(null);

    }

    const handleWssError = (evt) => {
        console.log("Wss Error", evt);

        evt.currentTarget.close();
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
        }}>
            <div style={{
                flex: "0 0 40px",
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
            }}>
                <div style={{
                    flex: "1 1 auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Typography style={{ color: '#eee' }}>Monitor:{deviceSN}</Typography>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <LinkIcon style={{ color: sck ? '#76ff03' : "#888" }} />
                </div>
            </div>
            <div style={{
                flex: "1 1 1px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                alignItems: 'stretch',
                flexWrap: "wrap",
                overflow: "auto",
                backgroundColor: "#666",
                padding: 5,
            }}>
                {/* Summary */}
                <div style={{
                    flex: "1 0 400px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    margin: 5,
                }}>
                    <DeviceSummary data={summaryData} />
                </div>
                <div style={{
                    flex: "1 0 350px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    margin: 5,
                }}>
                    <Alarm data={alarmData} />
                </div>
                {session.IsAdmin == 1 || session.IsDistributorUser == 1 ?
                    (<>
                        <div style={{
                            flex: "1 0 250px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            margin: 5,
                        }}>
                            <CellVoltage data={cellVoltageData} />
                        </div>
                        <div style={{
                            flex: "1 0 250px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            margin: 5,
                        }}>
                            <Temperature data={temperatureData} />
                        </div>
                        <div style={{
                            flex: "1 0 280px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            margin: 5,
                        }}>
                            <Relay data={relayData} />
                        </div>
                        <div style={{
                            flex: "1 0 280px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            margin: 5,
                        }}>
                            <Switch data={switchData} />
                        </div>
                        {/* Inverter Status */}
                        <div style={{
                            flex: "1 0 280px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            margin: 5,
                        }}>
                            <InverterStatus data={inverterStatusData} />
                        </div>
                    </>) : <div style={{ display: "none" }}></div>
                }
            </div>
        </div>
    )
}

export default Monitor;