import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../utils';
import { Table, Button, Form, Checkbox,} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';


const useStyles = makeStyles(() => ({
    tabs: {
        "& .ant-tabs-content": {
            height: "100%",
        },
    },
    settingInput: {
        "& ::before": {
            content: "√+",
            display: "inline-block",
        },
        "& .ant-form-item-control-input::after": {
            content: "√-"
        }
    },
    Checkbox: {
        "& .ant-checkbox-inner": {
            backgroundColor: "#666",
            border: "#666"
        }
     }
}));

function RoleManagement (props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [initialValues, setInitialValues] = useState({});

    const refTableContainer = useRef();

    const [form] = Form.useForm();

    const classes = useStyles();


    useEffect(() => {
        let active = true;
        console.log("RoleManagement Effect");

        (async () => {
            setLoading(true);
            
            
            let resp = await http.post("role/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[RoleManagement]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("RoleManagement Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        if (selectedKeys.length < 1) {
            return;
        }
        
        let active = true;

        (async () => {
            let resp = await http.post("role/management/role-permissions/get", {
               RoleID: selectedKeys[0],
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[RoleManagement]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setPermissions([]);
        
                return;
            }
            setPermissions(resp.data)

            const ivs = {};
            for (let p of resp.data) {
                const v = p.Permissions;
                const fv = p.FullPermissions;
                const vs = [];
                if (v & 1) {
                    vs.push("Create");
                }
                if (v & 2) {
                    vs.push("Retrieve");
                }
                if (v & 4) {
                    vs.push("Update");
                }
                if (v & 8) {
                    vs.push("Delete");
                }
                ivs[p.PermissionID] = vs;
            }
            setInitialValues(ivs);
            form.setFieldsValue(ivs);
        })();

        return () => {
            active = false;
        };
    }, [selectedKeys])

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const calcTableBodyHeight = () => {
        if (! refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return  h>=54 ? h - 54 : h /2;
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setPermissions([]);

        setSelectedKeys([...selectedRowKeys]);
        setSelectedRows([...selectedRows]);
    }

    const handleSave = async () => {
        const ps = form.getFieldsValue();
        const ps2 = [];
        const pp = {
            "Create": 1,
            "Retrieve": 2,
            "Update": 4,
            "Delete": 8,
        }
        for (let k in ps) {
            let v = 0;
            for (let s of ps[k]) {
                v += pp[s]
            }
            ps2.push(`${k}=${v}`);
        }

        try {
            const resp = await http.post("role/management/role-permissions/maintain/do", {
                RoleID: selectedRows[0].ID,
                Permissions: ps2.join("|"),
            }).then(async (r)=>{
                return await r.json();
            }).catch((e) => {
                console.log("[RoleManagement]", e);
                return null;
            });

            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "save error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'save success'});
        } catch(e) {
            console.log("[RoleManagement]", e)
        }
    }


    return (
        <div
            style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "row",
                padding: 8,
            }}
        >
            <div style={{
                flex: "0 0 400px",
                display: "flex",
                flexDirection: "column",
                border: '1px solid #aaa',
                borderRight: '0px',
            }}>
                <div style={{
                    flex:"1 1 1px",
                    position: "relative",
                }}>
                    <div ref={refTableContainer} style={{position: "absolute", height: '100%', width: "100%", overflow: "hidden"}}>
                        <Table 
                            columns={[{
                                title: "",
                                width: 50,
                                render: (v, r, idx) => {
                                    return (pageNo*pageSize+idx+1);
                                }
                            },{
                                title: "Name",
                                dataIndex: 'Name',
                                width: 200,
                            },{
                                title: "Enabled",
                                dataIndex: 'Enabled',
                                width: 80,
                                render: (v, r, index) => {
                                    // const vs = [];
                                    // if (v === 1 || v === "1") {
                                    //     vs.push("Enabled");
                                    // }
                                    // return (<Checkbox.Group className={classes.Checkbox} options={[{label: "", value: "Enabled"}]} value={vs} ></Checkbox.Group>)

                                    return v === 1 || v === "1" ? (<CheckOutlined style={{color: "blue"}}/>) 
                                        : (<CloseOutlined style={{color: "#ad2102"}} />)
                                }
                            }]}
                            pagination={{
                                pageSizeOptions: [25,50,100],
                                current: pageNo+1,
                                pageSize: pageSize,
                                total: rowTotal,
                                showSizeChanger: true,
                                showTotal: (total) => {return `Total ${total}`},
                                onShowSizeChange: (current, size) => {
                                    setPageSize(size);
                                },
                                onChange: (page, pageSize) => {
                                    setPageNo(page-1);                                
                                }
                            }}
                            tableLayout="fixed"
                            bordered={true}
                            size="middle"
                            loading={loading}
                            rowKey="ID"
                            dataSource={rows}
                            scroll={{ x: 250, y: tableBodyHeight }}
                            rowSelection={{
                                type: "radio",
                                selectedRowKeys: selectedKeys,
                                onChange: handleSelect,
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={{
                flex: '1 1 300px',
                display: "flex",
                flexDirection: "column",
                border: '1px solid #aaa',
            }}>
                <div style={{
                    fontSize: '20px',
                    fontWeight: "bold",
                    textAlign: 'center',
                    padding: '10px',
                }}>{selectedRows && selectedRows.length>0 ? selectedRows[0].Name : ""}&nbsp;Permissions</div>
                <div style={{
                    flex: '1 1 300px',
                    position: 'relative',
                }}>
                    <div style={{
                        height: "100%",
                        width: "100%",
                        position: 'absolute',
                    }}>
                        <Form
                            form={form}
                            initialValues={initialValues}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            layout="horizontal"
                            size="small"
                            style={{
                                width: "100%",
                            }}
                        >
                            {permissions.map((p) => {
                                return (
                                    <Form.Item
                                        key={p.PermissionID}
                                        label={<label style={{fontWeight:'bold'}}>{p.PermissionID}</label>}
                                        name={p.PermissionID}
                                    >
                                        <Checkbox.Group options={[{
                                            label:"Create", 
                                            value:"Create", 
                                            disabled: ((p.FullPermissions & 1) ? false: true)
                                        }, {
                                            label:"Retrieve", 
                                            value:"Retrieve",
                                            disabled: ((p.FullPermissions & 2) ? false: true)
                                        }, {
                                            label:"Update", 
                                            value:"Update",
                                            disabled: ((p.FullPermissions & 4) ? false: true)
                                        }, {
                                            label:"Delete", 
                                            value:"Delete",
                                            disabled: ((p.FullPermissions & 8) ? false: true)
                                        }]}/>
                                    </Form.Item>
                                )
                            })}
                        </Form>
                    </div>
                </div>
                <div style={{
                    flex: "0 0 auto",
                    padding: "10px",
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Button type="primary" onClick={()=>form.resetFields()}>Reset</Button>
                    <div style={{width:"50px"}}></div>
                    <Button type="primary" onClick={handleSave}>Save</Button>
                </div>
            </div>
            
        </div>
    );
}

export default RoleManagement;