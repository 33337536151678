import http from '../Http';
import { useContext, useState, useRef, useEffect } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select, Switch } from 'antd';
import timezone from '../utils/timezone'


function DistributorUserModifier (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose, row} = props;

    const [form] = Form.useForm();

    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);

    const [params, setParams] = useState({});
    
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [timezoneSelectOptions, setTimezoneSelectOptions] = useState([]);
    const refTimerTimezoneSearcher = useRef(null);
    const refTimezoneSearchValue = useRef(null);

    useEffect(() => {
        if (!row) {
            return;
        }

        let active = true;
        console.log("DistributorUserModifier Effect");

        (async () => {
            let resp = await http.post("user/management/distributor-user/detail/get", {
                UserID: row.ID,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[UserManagement]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setParams({});
        
                return;
            }
            if (resp.data && resp.data.length > 0) {
                const ps = resp.data[0];

                ps._DistributorID = ps.DistributorID
                ps.DistributorID = ps.DistributorName
                
                setParams(ps);
                form.setFieldsValue(ps);
            }
            
        })();

        return () => {
            active = false;
            console.log("UserManagement Effect Cancel");
        };
    }, [row]);

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        
        ps.Enabled = ps.Enabled ? 1 : 0;
        ps.Name = ps.Name || "";
        ps.Phone = ps.Phone || "";
        ps.EMail = ps.EMail || "";
        ps.Address = ps.Address || "";
        
        if (ps.DistributorID === params.DistributorID) {
            ps.DistributorID = params._DistributorID
        }

        const resp = await http.post("user/management/distributor-user/modify", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[DistributorUserModifier]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps.DistributorName = (resp.data.DistributorName || "");

            onClose({...params, ...ps});
        } catch (e) {
            console.log("[DistributorUserModifier]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load distributor list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={ID}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch(e) {
                console.log("[DeviceAdd]", e);

                setTip({open:true, severity:"error", msg: "load distributor list error"});
            }
        }, 300);
    }

    const handleSearchTimezone = (v) => {
        if (refTimerTimezoneSearcher.current != null) {
            clearTimeout(refTimerTimezoneSearcher.current);
        }
        refTimezoneSearchValue.current = v;


        refTimerTimezoneSearcher.current = setTimeout(async () => {
            const resp = await http.post("timezone/list", {
                Timezone: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refTimezoneSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load timezone list error" });
                    return;
                }

                const ls = resp.data.map(({ ZoneName, GmtOffset }) => {
                    var n = parseInt(GmtOffset);
                    var tz = "UTC+";
                    if (n < 0) {
                        tz = "UTC-";
                        n = Math.abs(n);
                    }
                    tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                    return (
                        <Select.Option key={ZoneName} value={ZoneName}>{ZoneName}({tz})</Select.Option>
                    );
                });
                setTimezoneSelectOptions(ls);
            } catch (e) {
                console.log("[Device-Adder]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }

    return (<Modal 
        title="Modify Distributor User"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            form={form}
            initialValues={params}
        >
            <Form.Item 
                name="DistributorID"
                label="Distributor"
                rules={[{ required: true }]}
            >
                <Select 
                    showSearch
                    placeholder="Enter Distributor Name"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchDistributor}
                >{distributorSelectOptions}</Select>
            </Form.Item>
            <Form.Item 
                name="Name"
                label="Title"
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="ID"
                label="UserID"
                rules={[{ required: true, min: 3 }]}
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item 
                name="Phone"
                label="Phone"
            >
                <Input placeholder="Phone Number"/>
            </Form.Item>
            <Form.Item 
                name="EMail"
                label="E-Mail"
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Address"
                label="Address"
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Timezone"
                label="Timezone"
                rules={[{ required: true, type: 'string' }]}
            >
                <Select
                    showSearch
                    placeholder="Timezone"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchTimezone}
                >{timezoneSelectOptions}</Select>
            </Form.Item>
            <Form.Item 
                name="Enabled"
                label="Enabled"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
        </Form>
    </Modal>);
}

export default DistributorUserModifier;