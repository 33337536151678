import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import config from '../Config';
import http from '../Http';
import utils from "../utils";
import { IconButton } from "@material-ui/core";
import ReactECharts from 'echarts-for-react';
import DateTimePicker from 'react-datetime-picker';

const useStyles = makeStyles({
    calender: {
        color: "white",
        "& input": {
            color: 'white'
        },
        "& .react-datetime-picker__clear-button": {
            display: "none"
        },
        "& .react-datetime-picker__calendar-button>svg": {
            stroke: "white"
        },
        "& .react-calendar__month-view__weekdays": {
            color:"black"
        },
        "& .react-calendar__month-view__days__day": {
            color:"black"
        },
        "& .react-calendar__month-view__days__day--weekend": {
            color:"#d10000"
        },
        "& .react-calendar__navigation__label__labelText--from": {
            color:"black"
        },
        "& .react-calendar__navigation__prev-button": {
            color:"black"
        },
        "& .react-calendar__navigation__prev2-button": {
            color:"black"
        },
        "& .react-calendar__navigation__next-button": {
            color:"black"
        },
        "& .react-calendar__navigation__next-button:disabled": {
            color:"white"
        },
        "& .react-calendar__navigation__next2-button": {
            color:"black"
        },
        "& .react-calendar__navigation__next2-button:disabled": {
            color:"white"
        }
    }
});

const option = {
    darkMode: true,
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        },
        renderMode: "richText",
        formatter: (params) => {
            return `{max|${params[2].seriesName} : ${(parseFloat(params[2].data) + parseFloat(params[0].data)).toFixed(1)}}\n{min|${params[0].seriesName}: ${params[0].data}}\n{avg|${params[1].seriesName}: ${params[1].data}}`;
        },
        rich: {
            max: {
                color: "blue"
            },
            min: {
                color: "bbb"
            },
            avg: {
                color: "red"
            }
        }
    },
    legend: {
        data: [{
            name:'max',
            icon: 'circle',
            itemStyle: {
                color: "blue",
            },
            textStyle: {
                color: "blue",
            }
        },{
            name:'min',
            icon: 'circle',
            itemStyle: {
                color: "#bbb",
            },
            textStyle: {
                color: "#bbb",
            }
        },{
            name:'avg',
            icon: 'circle',
            itemStyle: { 
                color: "red",
            },
            textStyle: {
                color: "red",
            }
        }],
    },
    toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
    grid: {
        left: 40,
        right: '4%',
        bottom: 40,
        containLabel: true
    },
    xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: ['1', '2', '3', '4', '5', '6', '7', '8', '9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
        name: "(Hour)",
            nameLocation: "center",
            nameGap: 25,
    }],
    yAxis: [
        {
            type: 'value',
            name: "(V)",
            nameLocation: "middle",
            nameRotate: 90,
            nameGap: 40,
        }
    ],
    series: [{
            name: 'min',
            type: 'line',
            stack: 'total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0,
            },
            emphasis: {
                focus: 'none', 
            },
            data: []
        },
        {
            name: 'avg',
            type: 'line',
            stack: '',
            smooth: true,
            lineStyle: {
                width: 1,
                color: "red"
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0,
            },
            emphasis: {
                focus: 'none'
            },
            data: []
        },
        {
            name: 'max',
            type: 'line',
            stack: 'total',
            smooth: true,
            lineStyle: {
                width: 0
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'blue' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#bbb' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }
            },
            emphasis: {
                focus: 'none'
            },
            data: []
        }]
};

function Voltage(props) {
    const { setTip, deviceID, deviceSN } = props;

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [date, setDate] = useState(new Date());

    const chartEl = useRef(null);
    const chart = useRef(null);

    const classes = useStyles();

    useEffect(() => {
        let active = true;
        const ymd = utils.dateFormat("YYYY-mm-dd", date);

        (async () => {
            let resp = await http.post("report/device/voltage/daily/report", {
                "DeviceID": deviceID,
                "DateTime": ymd
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("Report Voltage", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp == null || resp.code != 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                option.series[0].data = [];
                option.series[1].data = [];
                option.series[2].data = [];
            } else {
                let ds = {};
                let maxes = [];
                let mins = [];
                let avgs = [];

                resp.data.forEach((v) => {
                    ds[v['YMDH']] = v;
                });
                for (let i=1; i<25; i++) {
                    var ymdh;
                    if (i == 24) {
                        var x = utils.dateFormat("YYYY-mm-dd", new Date((new Date(date)).setDate(date.getDate()+1)));
                        ymdh = x+" "+"00:00:00";
                    } else {
                        ymdh = ymd+" "+i.toString().padStart(2, 0)+":00:00";
                    }
                    // const ymdh = ymd+" "+i.toString().padStart(2, 0)+":00:00";
                    if (ds[ymdh]) {
                        mins.push(((ds[ymdh]["Min"])/10).toFixed(1));
                        avgs.push((ds[ymdh]["Max"]/20 + ds[ymdh]["Min"]/20).toFixed(1));
                        maxes.push((ds[ymdh]["Max"]/10 - ds[ymdh]["Min"]/10).toFixed(1));
                    } else {
                        mins.push(0);
                        avgs.push(0);
                        maxes.push(0);
                    }
                }
                option.series[0].data = mins;
                option.series[2].data = maxes;
                option.series[1].data = avgs;
                //console.log(maxes);
            }

            //
            const instance = chart.current.getEchartsInstance();
            if (instance) {
                instance.setOption(option);
            }
        })();

        return () => {
            active = false;
        };
    }, [deviceSN, date]);

    const handleGoPreDay = () => {
        setDate(new Date(date.setDate(date.getDate()-1)));
    }

    const handleGoNextDay = () => {
        if (utils.dateFormat("YYYY-mm-dd", date) >= utils.dateFormat("YYYY-mm-dd", new Date())) {
            setTip({open:true, severity:"info", msg:"date exceeded"});
            return;
        }

        setDate(new Date(date.setDate(date.getDate()+1)));
    }

    return (
        <div style={{
            flex: "1 1 1px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            backgroundColor: "black",
        }}>
            <div style={{
                flex: '0 0 60px',
                display: "flex",
            }}>
                <IconButton onClick={handleGoPreDay}>
                    <NavigateBeforeIcon style={{ fontSize: 30, color: "white", }} />
                </IconButton>
                <div style={{
                    flex: '1 0 auto',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: "center",
                    color: "white",
                    fontSize: 20
                }}>
                    {/* {utils.dateFormat("YYYY-mm-dd", date)} */}
                    <DateTimePicker 
                        className={classes.calender}
                        disableClock={true}
                        value={new Date(date.getTime())}
                        format="yyyy-MM-dd"
                        maxDate={new Date()}
                        isCalendarOpen={isCalendarOpen}
                        locale="en"
                        onChange={(v) => {
                            setDate(v);
                        }}
                        onCalendarClose={() => {
                            setIsCalendarOpen(false);
                        }}
                    />
                </div>
                <IconButton onClick={handleGoNextDay}>
                    <NavigateNextIcon style={{ fontSize: 30, color: "white", }} />
                </IconButton>
            </div>
            <div style={{
                flex: "1 1 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: 'stretch',
                overflow: "auto",
                padding: 5,
                backgroundColor: "black",
            }}>
                <div style={{
                    flex: "1, 1 1px",
                    minWidth: 400,
                }} ref={chartEl}>
                    <ReactECharts ref={chart} option={option} style={{ height: 400 }} />
                </div>
            </div>
        </div>
    )
}

export default Voltage;