import config from './Config';
import http from "./Http";
import utils from './utils';
import React, { useContext, useState, useEffect, useRef } from 'react';

function WS (props) {
    const setTip = useContext(utils.tipContext);
    const {onConnect, onData, onClose, onError, ws} = props;
    const _ws = ws;
    if (! _ws) {
        _ws = new WS.WSInstance();
    }

    const [sck, setSck] = useState(null);

    const sckRef = useRef(null);
    const sns = useRef([]);

    const send = (typ, sn, action) => {
        if (! sckRef.current) {
            return;
        }

        const cmd = {
            "Type": typ,
            "ID": "99999",
            "DeviceSN": sn,
            "Action": action,
        };

        try {
            sckRef.current.send(JSON.stringify(cmd));
        } catch(e) {
            //
        }
    }

    _ws.register = (sn) => {
        send(1, sn, 1);

        if (sns.current.indexOf(sn) < 0) {
            sns.current =  [...sns.current, sn];
        }
    }

    _ws.unregister = (sn) => {
        send(1, sn, 0);

        if (sns.current.indexOf(sn)> -1) {
            sns.current.splice(sns.current.indexOf(sn), 1);
        }
    }

    useEffect(() => {
        if (sck) {
            return;
        }

        //console.log("WS===>", "useEffect[sck]")
        
        const wss = new WebSocket(config.wssUrl+"?Token="+http.token);
        wss.addEventListener('open', handleWssOpen);
        wss.addEventListener('message', handleWssMessage);
        wss.addEventListener('close', handleWssClose);
        wss.addEventListener('error', handleWssError);

        // return () => {
        //     wss.close();
        // }
    }, [sck]);

    useEffect(() => {
        return () => {
            if (sckRef.current) {
                sckRef.current.close();
            }
        }
    }, [])

    const handleWssOpen = (evt) => {
        const wss = evt.currentTarget;
        setSck(wss);
        sckRef.current = wss;

        if (sns.current.length > 0) {
            for (let sn of sns.current) {
                send(1, sn , 1);
            }
        }

        evt.currentTarget.tmrPing = setInterval(()=>{
            if (! wss.tmrPing) {
                return;
            }
    
            wss.send("Ping");
        }, 30000);

        if (onConnect) {
            onConnect();
        }
    }

    const handleWssMessage = (evt) => {
        //console.log("Wss Message", evt, evt.data);

        const d = evt.data;
        if (d == "Pong") {
            return;
        }

        //
        try {
            const data = JSON.parse(d);
            if (! data || data["Type"] != 'REALTIME') {
                return;
            }

            const x = JSON.parse(data["X"])

            if (onData) {
                onData(data["MsgID"], x);
            }
        } catch(e) {
            console.log("[WS]", e);
        }
    }

    const handleWssClose = (evt) => {
        console.log("Wss Close", evt);

        let wss = evt.currentTarget;
        wss.removeEventListener('open', handleWssOpen);
        wss.removeEventListener('message', handleWssMessage);
        wss.removeEventListener('close', handleWssClose);
        wss.removeEventListener('error', handleWssError);
        if (wss.tmrPing) {
            clearInterval(wss.tmrPing);
            wss.tmrPing = null;
        }

        setSck(null);
        sckRef.current = null;

        if (onClose) {
            onClose();
        }

    }

    const handleWssError = (evt) => {
        console.log("Wss Error", evt);

        evt.currentTarget.close();

        if (onError) {
            onError();
        }
    }

    return <div onClick={()=>{
        _ws.register("oooxxx");
    }}></div>
}

WS.WSInstance = function () {
    this.tag = Date.now();
}

export default WS;