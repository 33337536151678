import { ThemeContext } from '../Http';
import Monitor from '../Monitor';
import ProductType from '../DeviceManagement/ProductType';
import InverterManufacturer from '../DeviceManagement/InverterManufacturer';
import DeviceManagement from '../DeviceManagement';
import FirmwareManagement from '../Burning/FirmwareManagement';
import BurningManagement from '../Burning/BurningManagement';
import Setting from '../Setting/Setting';
import SettingHistory from '../Setting/SettingHistory';
import PermissionManagement from '../RoleManagement/PermissionManagement';
import RoleManagement from '../RoleManagement/RoleManagement';
import UserManagement from '../UserManagement/UserManagement';
import CustomerService from '../CustomerService';
import DistributorManagement from '../DistributorManagement/DistributorManagement';
import SummaryDataExporter from '../DataExport/SummaryDataExporter';
import AlarmDataExporter from '../DataExport/AlarmDataExporter';
import CellVoltageDataExporter from '../DataExport/CellVoltageDataExporter';
import TemperatureDataExporter from '../DataExport/TemperatureDataExporter';
import RelayDataExporter from '../DataExport/RelayDataExporter';
import SwitchDataExporter from '../DataExport/SwitchDataExporter';
import AlarmManagement from '../AlarmManagement/AlarmManagement';
import Dashboard from '../Dashboard/Dashboard';
import ComingSoon from './ComingSoon';
import React, { useContext } from "react";
import { createTheme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import UpdateIcon from '@material-ui/icons/Update';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import HistoryIcon from '@material-ui/icons/History';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import TuneIcon from '@material-ui/icons/Tune';
import ContactsIcon from '@material-ui/icons/Contacts';
import EvStationIcon from '@material-ui/icons/EvStation';



export default Navigator;

const theme = createTheme({});

const useStyles = makeStyles({
    listItemIconRoot: {
        padding: '0 3px',
        width: "30px",
        minWidth: "30px",
    },
});

function Navigator(props) {
    const { onNavigate, selectedKey } = props;

    const session = useContext(ThemeContext);

    const classes = useStyles();


    let ls = [];
    if (session.IsAdmin === 1) {
        ls = [{
            id: ".",
            title: 'Dashboard',
            icon: <VisibilityIcon />,
            action: function (props) { return <Dashboard {...props} />; },
        }, {
            id: 'WarnAndFaultHandle',
            title: 'Warn And Fault Handle',
            icon: <ReportProblemIcon />,
            action: function (props) { return <AlarmManagement {...props} />; },
        }, {
            id: 'DataAnalysis',
            title: 'Data Analysis',
            icon: <DataUsageIcon />,
            children: [{
                id: 'Monitor',
                title: 'Monitor',
                icon: <UpdateIcon />,
                action: (props) => {
                    return <Monitor {...props} />;
                },
            }, {
                id: 'Curve',
                title: 'Curve',
                icon: <MultilineChartIcon />,
                action: function (props) { return <ComingSoon />; },
            }],
        }, {
            id: 'DataExport',
            title: 'Data Export',
            icon: <HistoryIcon />,
            children: [{
                id: 'ExportSummaryData',
                title: 'Summary Data',
                action: function (props) {
                    return <SummaryDataExporter {...props} />;
                },
            }, {
                id: 'ExportWarnAndFaultData',
                title: 'Warn And Fault Data',
                action: function (props) { return <AlarmDataExporter {...props} />; },
            }, {
                id: 'ExportCellVoltageData',
                title: 'Cell Voltage Data',
                action: function (props) { return <CellVoltageDataExporter {...props} />; },
            }, {
                id: 'ExportTemperatureData',
                title: 'Temperature Data',
                action: function (props) { return <TemperatureDataExporter {...props} />; },
            }, {
                id: 'ExportRelayData',
                title: 'Realy Data',
                action: function (props) { return <RelayDataExporter {...props} />; },
            }, {
                id: 'ExportSwitchData',
                title: 'Switch Data',
                action: function (props) { return <SwitchDataExporter {...props} />; },
            }],
        }, {
            id: 'DeviceManagement',
            title: 'Device Management',
            icon: <StorageOutlinedIcon />,
            action: function (props) { return <DeviceManagement {...props} />; },
            children: [{
                id: 'ProductType',
                title: 'Product Type',
                action: function (props) { return <ProductType {...props} />; },
            }, {
                id: 'InverterManufacturer',
                title: 'Inverter Manufacturer',
                action: function (props) { return <InverterManufacturer {...props} />; },
            }],
        }, {
            id: 'DeviceBurning',
            title: 'Device Upgrade',
            icon: <SystemUpdateAltOutlinedIcon />,
            action: function (props) { return <BurningManagement {...props} />; },
            children: [{
                id: 'FirmwareManagement',
                title: 'Firmware Management',
                action: function (props) {
                    return <FirmwareManagement {...props} />;
                },
            }],
        }, {
            id: 'DeviceSetting',
            title: 'Device Setting',
            icon: <TuneIcon />,
            action: function (props) { return <Setting {...props} />; },
            children: [{
                id: 'SettingHistory',
                title: 'Setting History',
                action: function (props) {
                    return <SettingHistory {...props} />;
                },
            }],
        }, {
            id: 'DistributorManagement',
            title: 'Distributor Management',
            icon: <ApartmentIcon />,
            action: function (props) { return <DistributorManagement {...props} />; },
        }, {
            id: 'SystemManagement',
            title: 'System Management',
            icon: <SettingsIcon />,
            children: [{
                id: 'Role',
                title: 'Role',
                icon: <VerifiedUserIcon />,
                action: function (props) {
                    return <RoleManagement {...props} />;
                },
                children: [{
                    id: 'Permission',
                    title: "Permission",
                    action: function (props) {
                        return <PermissionManagement {...props} />;
                    },
                }]
            }, {
                id: 'User',
                title: 'User',
                icon: <PeopleOutlineIcon />,
                action: function (props) {
                    return <UserManagement {...props} />;
                },
            }, {
                id: 'CustomerService',
                title: 'Customer Service',
                icon: <ContactsIcon />,
                action: function (props) {
                    return <CustomerService {...props} />;
                },
            }],
        }];
    } else if (session.IsDistributorUser === 1) {
        ls = [{
            id: "Dashboard",
            title: 'Dashboard',
            icon: <VisibilityIcon />,
            action: function (props) { return <Dashboard {...props} />; },
        }, {
            id: 'WarnAndFaultHandle',
            title: 'Warn And Fault Handle',
            icon: <ReportProblemIcon />,
            action: function (props) { return <AlarmManagement {...props} />; },
        }, {
            id: 'DataAnalysis',
            title: 'Data Analysis',
            icon: <DataUsageIcon />,
            children: [{
                id: 'Monitor',
                title: 'Monitor',
                icon: <UpdateIcon />,
                action: (props) => {
                    return <Monitor {...props} />;
                },
            }, {
                id: 'Curve',
                title: 'Curve',
                icon: <MultilineChartIcon />,
                action: function (props) { return <ComingSoon />; },
            }],
        }, {
            id: 'DataExport',
            title: 'Data Export',
            icon: <HistoryIcon />,
            children: [{
                id: 'ExportSummaryData',
                title: 'Summary Data',
                action: function (props) {
                    return <SummaryDataExporter {...props} />;
                },
            }, {
                id: 'ExportWarnAndFaultData',
                title: 'Warn And Fault Data',
                action: function (props) { return <AlarmDataExporter {...props} />; },
            }, {
                id: 'ExportCellVoltageData',
                title: 'Cell Voltage Data',
                action: function (props) { return <CellVoltageDataExporter {...props} />; },
            }, {
                id: 'ExportTemperatureData',
                title: 'Temperature Data',
                action: function (props) { return <TemperatureDataExporter {...props} />; },
            }, {
                id: 'ExportRelayData',
                title: 'Realy Data',
                action: function (props) { return <RelayDataExporter {...props} />; },
            }, {
                id: 'ExportSwitchData',
                title: 'Switch Data',
                action: function (props) { return <SwitchDataExporter {...props} />; },
            }],
        }, {
            id: 'DeviceManagement',
            title: 'Device Management',
            icon: <StorageOutlinedIcon />,
            action: function (props) { return <DeviceManagement {...props} />; },
        }, {
            id: 'DeviceBurning',
            title: 'Device Upgrade',
            icon: <SystemUpdateAltOutlinedIcon />,
            action: function (props) { return <BurningManagement {...props} />; },
        }, {
            id: 'DeviceSetting',
            title: 'Device Setting',
            icon: <TuneIcon />,
            action: function (props) { return <Setting {...props} />; },
            children: [{
                id: 'SettingHistory',
                title: 'Setting History',
                action: function (props) {
                    return <SettingHistory {...props} />;
                },
            }],
        }, {
            id: 'DistributorManagement',
            title: 'Distributor Management',
            icon: <ApartmentIcon />,
            action: function (props) { return <DistributorManagement {...props} />; },
        }, {
            id: 'SystemManagement',
            title: 'System Management',
            icon: <SettingsIcon />,
            children: [{
                id: 'User',
                title: 'User',
                icon: <PeopleOutlineIcon />,
                action: function (props) {
                    return <UserManagement {...props} />;
                },
            }, {
                id: 'CustomerService',
                title: 'Customer Service',
                icon: <ContactsIcon />,
                action: function (props) {
                    return <CustomerService {...props} />;
                },
            }],
        }];
    } else { // is end-user
        ls = [{
            id: 'WarnAndFaultHandle',
            title: 'Warn And Fault Handle',
            icon: <ReportProblemIcon />,
            action: function (props) { return <AlarmManagement {...props} />; },
        }, {
            id: 'DataAnalysis',
            title: 'Data Analysis',
            icon: <DataUsageIcon />,
            children: [{
                id: 'Monitor',
                title: 'Monitor',
                icon: <UpdateIcon />,
                action: (props) => {
                    return <Monitor {...props} />;
                },
            }, {
                id: 'Curve',
                title: 'Curve',
                icon: <MultilineChartIcon />,
                action: function (props) { return <ComingSoon />; },
            }],
        }, {
            id: 'SystemManagement',
            title: 'System Management',
            icon: <SettingsIcon />,
            children: [{
                id: 'CustomerService',
                title: 'Customer Service',
                icon: <ContactsIcon />,
                action: function (props) {
                    return <CustomerService {...props} />;
                },
            }],
        }];
    }


    return subNavigator(0, ls, onNavigate, selectedKey, classes);
}

function subNavigator(level, ls, onNavigate, selectedKey, classes) {
    let cs = [];

    let j = 0;
    for (let i = 0; i < ls.length; i++) {
        if (ls[i].children && ls[i].children.length > 0) {
            cs.push(generateNavigator(level, ls.slice(j, i + 1), onNavigate, selectedKey, classes));
            cs.push(...subNavigator(level + 1, ls[i].children, onNavigate, selectedKey, classes));
            j = i + 1;
        } else if (i + 1 >= ls.length) {
            cs.push(generateNavigator(level, ls.slice(j, i + 1), onNavigate, selectedKey, classes));
        }
    }

    return cs;
}

function generateNavigator(level, ls, onNavigate, selectedKey, classes) {
    return (
        <List
            key={ls[0].id}
            style={{ padding: "0px" }}
        >
            {ls.map((e) => (
                <ListItem button dense
                    key={e.id}
                    selected={selectedKey === e.id ? true : false}
                    style={{ paddingLeft: theme.spacing(level * 4), color: "darkslateblue" }}
                    onClick={() => {
                        if (selectedKey === e.id) {
                            return;
                        }

                        if (onNavigate) {
                            onNavigate(e.id, e.action);
                        }
                    }}
                >
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }} style={{ color: "darkslateblue" }}>{e["icon"]}</ListItemIcon>
                    <ListItemText primary={e["title"]} />
                </ListItem>
            ))}
        </List>
    );
}
