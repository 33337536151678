import config from './Config';
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from './Http';
import utils from './utils';
import React from 'react';
import { alpha, createTheme, makeStyles } from '@material-ui/core/styles';
import {Snackbar} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import HomeNavigator from './Home/Navigator';
import DeviceListFor from "./DeviceListFor";
import Monitor from './Monitor';
import Curve from './Curve';
import Dashboard from './Dashboard/Dashboard';
import PasswordChanger from "./Home/PasswordChanger";
import Inverter2 from './Monitor/Inverter2/Inverter2';
import "./Home.css";

const theme = createTheme({});

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

function Home(props) {
    const {whichSystem, onChangeSystem} = props;

    const classes = useStyles();

    const session = useContext(ThemeContext);
    const sm = theme.breakpoints.up("sm");

    const [mUserAnchorEl, setMUserAnchorEl] = useState(null);
    const [mMoreActionAnchorEl, setMMoreActionAnchorEl] = useState(null);
    const [pageID, setPageID] = useState(session.IsAdmin==1||session.IsDistributorUser==1 ? "Dashboard" : "Monitor");
    const [PageGenerator, setPageGenerator] = useState((prop) => {return session.IsAdmin==1||session.IsDistributorUser==1 ? <Dashboard/> : <Monitor/>});
    const [tip, setTip] = useState({open:false, severity:'', msg:''});
    const [deviceID, setDeviceID] = useState(null);
    const [deviceSN, setDeviceSN] = useState(null);
    const [showPasswordChanger, setShowPasswordChanger] = useState(false);
    
    const openUserMenu = Boolean(mUserAnchorEl);
    const openMoreActionMenu = Boolean(mMoreActionAnchorEl);

    //Inverter Management
    const [imsIndex, setImsIndex] = useState(0);


    
    useEffect(()=>{
        // console.log("Home Effect");

        // return () => {
        //     console.log("Home Effect Cancel")
        // }
    })

    const handleUserMenuOpen = (e) => {
        setMUserAnchorEl(e.currentTarget);
    }

    const handleMoreActionMenuOpen = (e) => {
        setMMoreActionAnchorEl(e.currentTarget);
    }

    const handleUserMenuClose = () => {
        setMUserAnchorEl(null);
    };

    const handleMoreActionMenuClose = () => {
        setMMoreActionAnchorEl(null);
    }

    const handleMyAccountOpen = () => {
        //
        setMUserAnchorEl(null);
    }

    const handleChangePassword = () => {
        setMUserAnchorEl(null);
        
        setShowPasswordChanger(true);
    }

    const handleDeviceSelect = (id, sn) => {
        setDeviceID(id);
        setDeviceSN(sn);
    }

    const handleNavigate = (id, pageGenerator) => {
        if (! pageGenerator || pageID == id) {
            return;
        }

        setPageID(id);
        setPageGenerator(pageGenerator);        
    }

    const handleConverToMonitor = (id, sn) => {
        setDeviceID(id);
        setDeviceSN(sn);
    }

    const indexIms = (idx) => {
        document.querySelectorAll(".btn-ims-index").forEach((e)=>{
            e.attributes["indexed"].value="0";
        });
        setImsIndex(idx);
        document.querySelector(".btn-ims-index[index=\""+idx+"\"]").attributes["indexed"].value="1";
    }

    const mUser = (
        <Menu
            id='mUser'
            anchorEl={mUserAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openUserMenu}
            onClose={handleUserMenuClose}
        >
            {/* <MenuItem onClick={handleMyAccountOpen}>My Account</MenuItem> */}
            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
        </Menu>
    );

    const mMoreAction = (
        <Menu
            id="mMoreAction"
            anchorEl={mMoreActionAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openMoreActionMenu}
            onClose={handleMoreActionMenuClose}
        >
            <MenuItem>
                <IconButton color="inherit">
                    <Badge badgeContent={99} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton color="inherit">
                    <Badge badgeContent={99} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleUserMenuOpen}>
                <IconButton
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (<utils.tipContext.Provider value={setTip}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: "100vw",
            height: '100vh',
        }}>
            {/* App Bar */}
            <AppBar position="relative" style={{
                backgroundColor: "rgb(99, 173, 130)",
                paddingTop: "10px",
                paddingBottom: "10px"
            }}>
                    <Toolbar>
                        <img style={{
                            height: "40px",
                            width: "auto"
                        }} src={config.appIcon} />
                        <div style={{width:"30px"}}></div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Tooltip title={config.appName_Tip_General} enterDelay={500}>
                                <Button variant="outlined" size='medium' 
                                    style={{
                                        color:(whichSystem=="BMS"?"white":"#ddd"),
                                        backgroundColor:"rgba(221,221,221, 0.4)",
                                        border: (whichSystem=="BMS"?"1px solid white":"")
                                    }}
                                    onClick={()=>{
                                        onChangeSystem("BMS")
                                    }}
                                >
                                    {config.appName_General}
                                </Button>
                            </Tooltip>
                            <div style={{height:"8px"}}></div>
                            <Tooltip title={config.appName_Tip_IMS} enterDelay={500}>
                                <Button variant="outlined" size='medium' 
                                    style={{
                                        color:(whichSystem=="IMS"?"white":"#ddd"),
                                        backgroundColor:"rgba(221,221,221, 0.4)",
                                        border: (whichSystem=="IMS"?"1px solid white":"")
                                    }}
                                    onClick={()=>{
                                        onChangeSystem("IMS")
                                    }}
                                >
                                    {config.appName_IMS}
                                </Button>
                            </Tooltip>
                        </div>
                        <div style={{width:"20px"}}></div>
                        {/* Inverter Management Buttons */}
                        {/* == Monitor == */}
                        <div hidden={whichSystem!="IMS"} index={0} indexed={1} className='btn-ims-index' onClick={()=>{indexIms(0)}}>
                            <img src="images/inverter/monitor.png" />
                            <div>Monitor</div>
                        </div>
                        {/* == Data Analysis == */}
                        <div hidden={whichSystem!="IMS"} index={1} indexed={0} className='btn-ims-index' onClick={(e)=>{indexIms(1);}}>
                            <img src="images/inverter/data.png" />
                            <div>Data</div>
                        </div>
                        {/* == Monitor == */}
                        <div hidden={whichSystem!="IMS"} index={3} indexed={0} className='btn-ims-index' onClick={()=>{indexIms(3);}}>
                            <img src="images/inverter/outline.png" />
                            <div>Outline</div>
                        </div>
                        {/* == Setting == */}
                        <div hidden={whichSystem!="IMS"} index={4} indexed={0} className='btn-ims-index' onClick={()=>{indexIms(4);}}>
                            <img src="images/inverter/setting.png" />
                            <div>Setting</div>
                        </div>
                        <div className={classes.grow} />
                        {/* Searcher , display none 2021.8.31*/}
                        <div className={classes.search} style={{display: "none"}}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                        <div className={classes.sectionDesktop}>
                            {/* display none 2021.9.2 */}
                            <IconButton aria-label="show 4 new mails" color="inherit" style={{display: "none"}}>
                                <Badge badgeContent={99} color="secondary">
                                    <MailIcon />
                                </Badge>
                            </IconButton>
                            <IconButton aria-label="show 17 new notifications" color="inherit">
                                <Badge badgeContent={0} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-haspopup="true"
                                onClick={handleUserMenuOpen}
                                color="inherit"
                            >
                                <Tooltip title={session===null?"":session.Name||session.UserID}>
                                    <AccountCircle />
                                </Tooltip>
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-haspopup="true"
                                onClick={handleMoreActionMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
            <div style={{
                flex: '1 1 auto',
                display: "flex",
                flexDirection: 'row',
                alignItems: 'stretch',
                overflow: "hidden",
            }}>
                {whichSystem == "BMS" 
                ?
                (<>
                    {/* Navigator Bar */}
                    <div style={{
                        flex: "0 0 300px",
                        backgroundColor: "#eee",
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'stretch',
                        alignItems: "stretch",
                    }}>
                        <div style={{
                            flex: '1 1 auto',
                            height: "100px",
                            overflow: "auto",
                        }}>
                            <HomeNavigator 
                                selectedKey={pageID}
                                onNavigate={handleNavigate}
                            />
                        </div>
                    </div>
                    {/* Pages */}
                    <div style={{
                        flex: '1 1 auto',
                        display: "flex",
                        alignItems: "stretch",
                    }}>
                        {(pageID === 'Monitor' || pageID === 'Curve') ?(
                            <div style={{
                                flex: "1 1 auto",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "stretch",
                            }}>
                                <div style={{
                                    flex: "0 0 250px",
                                    border: "1px solid #aaa",
                                }}>
                                    {<DeviceListFor 
                                        setTip={setTip}
                                        onSelect={handleDeviceSelect}
                                        deviceSN={deviceSN}
                                    />}
                                </div>
                                <div style={{
                                    flex: "1 1 auto",
                                    display: "flex",
                                    alignItems: "stretch",
                                    overflow: "hiddend"
                                }}>
                                    {pageID === 'Monitor' ? (<Monitor 
                                        setTip= {setTip}
                                        deviceID= {deviceID}
                                        deviceSN= {deviceSN}
                                    />):(<Curve 
                                        setTip= {setTip}
                                        deviceID= {deviceID}
                                        deviceSN= {deviceSN}
                                    />)}
                                </div>
                            </div>
                        ) : (<PageGenerator.type onNavigate={handleNavigate} handleConverToMonitor={handleConverToMonitor} />)}
                    </div>
                </>)
                :
                    <Inverter2 activeMenu={imsIndex} {...props}/>
                    // <div>abc</div>
                }
            </div>
            {mMoreAction}
            {mUser}
            <Snackbar
                autoHideDuration={3000}
                open={tip.open} 
                anchorOrigin={{
                    horizontal:"right",
                    vertical:"bottom",
                }}
                onClose={()=>{
                    setTip({open:false, severity:tip.severity});
                }}
            >
                <MuiAlert 
                    elevation={6} 
                    variant="filled" 
                    severity={tip.severity}
                    onClose={()=>{
                        setTip({open:false, severity:tip.severity});
                    }}
                >
                    {tip.msg}
                </MuiAlert>
            </Snackbar>
        </div>
        <PasswordChanger show={showPasswordChanger} onClose={() => {setShowPasswordChanger(false);}}/>
    </utils.tipContext.Provider>);
}

export default Home;