import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../utils';
import { Table, Input, Button, Modal, Form, Row, Col, Checkbox, Select, Popover, Menu, Dropdown } from 'antd';
import { CloudDownloadOutlined, ExclamationCircleOutlined, UpOutlined, DownOutlined, SwapRightOutlined } from '@ant-design/icons';
import defs from "./defs"
import BurningAddGeneral from "./BurningAddGeneral";
import BurningAddGeneralPassThrough from "./BurningAddGeneralPassThrough";
import BurningAddEnergyPortPassthrough from "./BurningAddEnergyPortPassthrough.js";
import BurningAddMasterControl from "./BurningAddMasterControl.js";
import BurningController from "./BurningController";


const useStyles = makeStyles(() => ({
    df: {
        backgroundColor: "#e5ffff"
    }
}));

function BurningManagement(props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        SN: '',
        ProductName: '',
        DistributorName: '',
        UserID: '',
        FirmwareName: '',
        BurningType: '',
        FirmwareVersion: '',
        Burned: '',
        NotExpired: '',
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [showAddGeneral, setShowAddGeneral] = useState(false);
    const [showAddGeneralPassThrough, setShowAddGeneralPassThrough] = useState(false);
    const [showAddEnergyPortPassthrough, setShowAddEnergyPortPassthrough] = useState(false);
    const [showAddMasterControl, setShowAddMasterControl] = useState(false);
    const [productSelectOptions, setProductSelectOptions] = useState([]);
    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
    const [expand, setExpand] = useState(false);
    const [controlBurningInfo, setControlBurningInfo] = useState(null);

    const refTableContainer = useRef();
    const refTimerProductSearcher = useRef(null);
    const refProductSearchValue = useRef(null);
    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const [form] = Form.useForm();

    const classes = useStyles();

    useEffect(() => {
        let active = true;
        console.log("BurningManagement Effect");

        (async () => {
            setLoading(true);


            let resp = await http.post("burning/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningManagement]", e);
                return null;
            });

            if (!active) {
                return;
            }

            if (resp === null || resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load data error" });

                setRowTotal(0)
                setRows([]);

                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("BurningManagement Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }

        let obj = document.createElement('object');
        el.objResizer = obj;
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (!el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const handleSearchProduct = (v) => {
        if (refTimerProductSearcher.current != null) {
            clearTimeout(refTimerProductSearcher.current);
        }
        refProductSearchValue.current = v;

        refTimerProductSearcher.current = setTimeout(async () => {
            const resp = await http.post("product/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningManagement]", e);
                return null;
            });

            if (refProductSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load product list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setProductSelectOptions(ls);
            } catch (e) {
                console.log("[BurningManagement]", e);

                setTip({ open: true, severity: "error", msg: "load product list error" });
            }
        }, 300);
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningManagement]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load distributor list error" });
                    return;
                }

                const ls = resp.data.map(({ Name, ID }) => {
                    return (
                        <Select.Option key={ID} value={Name}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch (e) {
                console.log("[BurningManagement]", e);

                setTip({ open: true, severity: "error", msg: "load distributor list error" });
            }
        }, 300);
    }

    const handleSearch = () => {
        setSelectedKeys([]);
        setPageNo(0);

        const ps = form.getFieldsValue();
        //console.log(ps, "<<<------")
        ps.SN = ps.SN || "";
        ps.FirmwareName = ps.FirmwareName || "";
        ps.ProductName = ps.ProductName || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.UserID = ps.UserID || "";
        ps.BurningType = ps.BurningType || "";
        ps.FirmwareVersion = ps.Version || "";
        ps.Burning = (ps.Burning || []).join(",")
        setParams({ ...ps });
    }

    const renderAction = (v, r, idx) => {
        if (r.BurningCategory === "1" && r.ViaMaster !== "0") {
            return;
        }

        const btns = [];
        const btnIgnore = <Button key="Ignore" onClick={() => { handleIgnore(r, idx) }} type="primary" size="small" style={{ marginRight: "3px" }}>Ignore</Button>;
        const btnRestart = <Button key="Restart" onClick={() => { handleRestart(r, idx) }} type="primary" size="small" style={{ marginRight: "3px" }}>Restart</Button>;
        const btnControl = <Button key="Control" onClick={() => { handleControl(r, idx) }} type="primary" size="small" style={{ marginRight: "3px" }} >Control</Button>;
        if (r.EventCode === "99999") {
            btns.push(<Button key="Confirm" onClick={() => { handleConfirm(r, idx) }} type="primary" size="small" style={{ marginRight: "3px" }} >Confirm</Button>);
            btns.push(btnIgnore);
        } else if (r.EventCode === "9" || r.EventCode === "1") {
            btns.push(btnControl);
            btns.push(btnIgnore);
        } else if (r.EventCode === "3" || r.EventCode === "5") {
            btns.push(btnRestart);
            btns.push(btnIgnore);
        }

        return (
            <>{btns}</>
        )
    }

    const handleAdd = (item) => {
        if (item.key === "GeneralBurning") {
            setShowAddGeneral(true);
        } else if (item.key === "GeneralPass-throughBurning") {
            setShowAddGeneralPassThrough(true);
            //} else if (item.key === "EnergyPortBurning") {
            //    setTip({open:true, severity:"info", msg:"Energy Port Burning Coming Soon"})
        } else if (item.key === "EnergyPortPass-throughBurning") {
            //setTip({open:true, severity:"info", msg:"Energy Port Pass-through Burning Coming Soon"})
            setShowAddEnergyPortPassthrough(true);
        } else if (item.key === "Master-ControlBurning") {
            setShowAddMasterControl(true);
        }
    }

    const handleConfirm = async (row, index) => {
        try {
            const resp = await http.post("device/upgrade/request/do", {
                DeviceID: row.ID,
                BurningID: row.BurningID,
                FirmwareID: row.FirmwareID,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningManagement]", e);
                return null;
            });

            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "Confirm error" });
                return;
            }

            const data = resp.data;

            if (data["EventCode"] === 1 || data["EventCode"] === 9) {
                setTip({ open: true, severity: "success", msg: "Confirm success" })

                setRows([...rows.slice(0, index), { ...row, EventCode: data["EventCode"].toString() }, ...rows.slice(index + 1)]);
            } else {
                setTip({ open: true, severity: "error", msg: "Confirm error" });
            }
        } catch (e) {
            console.log("[BurningManagement]", e)
            setTip({ open: true, severity: "error", msg: "Confirm error" });
        }
    }

    const handleIgnore = async (row, index) => {
        try {
            const resp = await http.post("device/upgrade/ignore/do", {
                DeviceID: row.ID,
                BurningID: row.BurningID,
                FirmwareID: row.FirmwareID,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningManagement]", e);
                return null;
            });

            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "Ignore error" });
                return;
            }

            const data = resp.data;

            if (data["EventCode"] === 0) {
                setTip({ open: true, severity: "success", msg: "Ignore success" })

                setRows([...rows.slice(0, index), { ...row, EventCode: data["EventCode"].toString() }, ...rows.slice(index + 1)]);
            } else {
                setTip({ open: true, severity: "error", msg: "Ignore error" });
            }
        } catch (e) {
            console.log("[BurningManagement]", e)
            setTip({ open: true, severity: "error", msg: "Ignore error" });
        }
    }

    const handleControl = async (row, index) => {
        setControlBurningInfo({ ...row });
    }

    const handleRestart = async (row, index) => {
        try {
            const resp = await http.post("device/upgrade/request/do", {
                DeviceID: row.ID,
                BurningID: row.BurningID,
                FirmwareID: row.FirmwareID,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[BurningManagement]", e);
                return null;
            });

            if (resp.code !== 200) {
                setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "Restart error" });
                return;
            }

            const data = resp.data;

            if (data["EventCode"] === 1 || data["EventCode"] === 9) {
                setTip({ open: true, severity: "success", msg: "Restart success" })

                setRows([...rows.slice(0, index), { ...row, EventCode: data["EventCode"].toString() }, ...rows.slice(index + 1)]);
            } else {
                setTip({ open: true, severity: "error", msg: "Restart error" });
            }
        } catch (e) {
            console.log("[BurningManagement]", e)
            setTip({ open: true, severity: "error", msg: "Restart error" });
        }
    }


    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAddingClose = (rs) => {
        setShowAddGeneral(false);
        setShowAddGeneralPassThrough(false);
        setShowAddEnergyPortPassthrough(false);
        setShowAddMasterControl(false);

        if (!rs) {
            return;
        }

        // document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        // setSelectedKeys([...(rs instanceof Array ? rs.map((r)=>r.ID) : [rs.ID])]);
        // setRows([...(rs instanceof Array ? rs : [rs]), ...rows]);
        // setRowTotal(rowTotal+(rs instanceof Array ? rs.length : 1));

        handleSearch();
    }

    const calcTableBodyHeight = () => {
        if (!refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return h >= 54 ? h - 54 : h / 2;
    }

    //按下Enter键时进行查找
    const searchByEnter = (e) => {
        if (e.code == "Enter") {
            handleSearch()
        }
    }

    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form
                    form={form}
                    initialValues={{
                        Burning: ["No-Upgrade", 'Done', 'Ignored', "Expired", 'To-Be-Confirmed', 'Errored', 'In-Progress'],
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={6} key="SN">
                            <Form.Item
                                name="SN"
                                label="SN"
                            >
                                <Input placeholder="Enter Device SN" onPressEnter={handleSearch} />
                            </Form.Item>
                        </Col>
                        <Col span={6} key="Product">
                            <Form.Item
                                name="ProductName"
                                label="Product"
                            >
                                <Select
                                    showSearch
                                    placeholder="Enter Product Name"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearchProduct}
                                    onInputKeyDown={searchByEnter}
                                >{productSelectOptions}</Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} key="Distributor">
                            <Form.Item
                                name="DistributorName"
                                label="Distributor"
                            >
                                <Select
                                    showSearch
                                    placeholder="Enter Distributor Name"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearchDistributor}
                                    onInputKeyDown={searchByEnter}
                                >{distributorSelectOptions}</Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} key="UserID">
                            <Form.Item
                                name="UserID"
                                label="UserID"
                            >
                                <Input placeholder="Enter User ID" onPressEnter={handleSearch} />
                            </Form.Item>
                        </Col>
                        <Col span={6} key="FirmwareName" style={{ display: expand ? "block" : "none" }}>
                            <Form.Item
                                name="FirmwareName"
                                label="FirmwareName"
                            >
                                <Input placeholder="Enter Firmware Name" onPressEnter={handleSearch} />
                            </Form.Item>
                        </Col>
                        <Col span={6} key="BurningType" style={{ display: expand ? "block" : "none" }}>
                            <Form.Item
                                name="BurningType"
                                label="UpgradeType"
                            >
                                <Select onInputKeyDown={searchByEnter}>
                                    <Select.Option value={1}>APP</Select.Option>
                                    <Select.Option value={2}>BOOT</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} key="FirmwareVersion" style={{ display: expand ? "block" : "none" }}>
                            <Form.Item
                                name="FirmwareVersion"
                                label="FirmwareVersion"
                            >
                                <Input placeholder="e.g.: 1.8.8" onPressEnter={handleSearch} />
                            </Form.Item>
                        </Col>
                        <Col span={24} key="Burning" style={{ display: expand ? "block" : "none" }}>
                            <Form.Item
                                name="Burning"
                                label=""
                            >
                                <Checkbox.Group options={["No-Upgrade", 'Done', 'Ignored', "Expired", 'To-Be-Confirmed', 'Errored', 'In-Progress']} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={handleSearch}>
                                Search
                            </Button>
                            <Button
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                            <a
                                style={{ fontSize: 12 }}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                {expand ? <UpOutlined /> : <DownOutlined />} {expand ? 'Collapse' : 'Expand'}
                            </a>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                <Dropdown
                    overlay={<Menu onClick={handleAdd}>
                        <Menu.Item key="GeneralBurning">General Upgrade</Menu.Item>
                        <Menu.Item key="GeneralPass-throughBurning">General Pass-through Upgrade</Menu.Item>
                        {/* <Menu.Item key="EnergyPortBurning">Energy Port Burning</Menu.Item> */}
                        <Menu.Item key="EnergyPortPass-throughBurning">Energy Port Pass-through Upgrade</Menu.Item>
                        <Menu.Item key="Master-ControlBurning">Master-Control Upgrade</Menu.Item>
                    </Menu>}
                >
                    <Button type="primary" icon={<CloudDownloadOutlined />} size="middle" style={{ marginLeft: 5 }}>Add Upgrade</Button>
                </Dropdown>
            </div>
            <div style={{
                flex: "1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{ position: "absolute", height: '100%', width: "100%", overflow: "hidden" }}>
                    <Table
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo * pageSize + idx + 1);
                            }
                        }, {
                            title: "SN",
                            dataIndex: 'SN',
                            width: 220,
                            fixed: 'left',
                            render: (v, r) => {
                                const content = (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 90px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>Product:&nbsp;</div>
                                            <div style={{ flex: "1" }}>{r.ProductName}</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 90px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>Distributor:&nbsp;</div>
                                            <div style={{ flex: "1" }}>{r.DistributorName}</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 90px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>Users:&nbsp;</div>
                                            <div style={{ flex: "1" }}>{r.Users}</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 90px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>BurnedBoot:&nbsp;</div>
                                            <div style={{ flex: "1" }}>{r.BurnedBootFirmwareVersion ? "V" + r.BurnedBootFirmwareVersion + ", " : ""}&nbsp;{r.BurnedBootFirmwareEventTime || ""}</div>
                                        </div>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                flex: "0 0 90px",
                                                textAlign: "right",
                                                fontWeight: "bold",
                                            }}>BurnedApp:&nbsp;</div>
                                            <div style={{ flex: "1" }}>{r.BurnedAppFirmwareVersion ? "V" + r.BurnedAppFirmwareVersion + ", " : ""}&nbsp;{r.BurnedAppFirmwareEventTime || ""}</div>
                                        </div>
                                    </div>
                                );
                                return (
                                    <Popover title="Note" content={content}>
                                        <div>{v}</div>
                                    </Popover>
                                );
                            }
                        }, {
                            title: "Upgrade Category",
                            dataIndex: "BurningCategory",
                            width: 200,
                            render: (v) => {
                                const cs = {
                                    "0": "General",
                                    "1": "General Pass-through",
                                    "2": "Energy Port",
                                    "3": "Energy Port Pass-through",
                                    "4": "Master-Control",
                                }

                                return cs[v] || "";
                            }
                        }, {
                            title: "FirmwareName",
                            dataIndex: "FirmwareName",
                            width: 200,
                        }, {
                            title: "FirmwareVersion",
                            dataIndex: "FirmwareVersion",
                            width: 80,
                        }, {
                            title: "Upgrade Type",
                            dataIndex: "BurningType",
                            width: 100,
                            render: (v, r, idx) => {
                                return defs.burningTypes[v] || "";
                            }
                        }, {
                            title: "DataLength",
                            dataIndex: "DataLength",
                            width: 140,
                            render: (v) => {
                                return v ? Math.round(v/1024) + 'KB (' + v + 'B)' : ''
                            }
                        }, {
                            title: "ExpiredTime",
                            dataIndex: "ExpiredTime",
                            width: 150,
                            render: (v) => {
                                return v && v != "0000-00-00 00:00:00" ? v : "";
                            }
                        }, {
                            title: "Note",
                            dataIndex: "Note",
                            width: 200,
                            render: (v) => {
                                if (!v) {
                                    return v;
                                }

                                if (v.indexOf("\n") > -1) {
                                    const content = (
                                        <Input.TextArea rows={5} value={v} readOnly />
                                    )
                                    return (
                                        <Popover title="Note" content={content}>
                                            <div>{v}</div>
                                        </Popover>
                                    );
                                }

                                return v;
                            }
                        }, {
                            title: "Master Pass-through | Via Master",
                            dataIndex: "UpgradingDeviceSNs",
                            width: 200,
                            className: classes.df,
                            render: (v, r) => {
                                if (r["ViaMaster"] === null) {
                                    return "";
                                } else if (r["ViaMaster"] !== "0") {
                                    return "ViaMaster";
                                } else {
                                    return v;
                                }
                            }
                        }, {
                            title: "Upgrade Time",
                            dataIndex: "STime",
                            width: 200,
                            className: classes.df,
                            render: (v, r) => {
                                if (v) {
                                    return (
                                        <p>
                                            {v}&nbsp;
                                            <SwapRightOutlined color="#aaa" />
                                            &nbsp;{r["ETime"] || ""}
                                        </p>
                                    )
                                } else {
                                    return "";
                                }
                            }
                        }, {
                            title: "Progress",
                            dataIndex: "Progress",
                            width: 200,
                            className: classes.df,
                            render: (v, r) => {
                                const code = r["EventCode"];
                                if (code === "99999") {
                                    return "To be confirmed";
                                } else if (code === "0") {
                                    return "Ignored";
                                } else if (code === "9") {
                                    return "To be burned";
                                } else if (code === "2") {
                                    return <label style={{ color: "#32cb00" }}>Burned</label>;
                                } else if (code === "3") {
                                    return <label style={{ color: "red" }}>Error</label>;
                                } else if (code === "4") {
                                    return "Expired";
                                } else if (code === "1") {
                                    return <label style={{ color: "blue" }}>{(v * 100 / r["DataLength"]).toFixed(1) + "%"}</label>;
                                }
                                if (v) {
                                    return (
                                        <p>
                                            {v}&nbsp;
                                            <SwapRightOutlined color="#aaa" />
                                            &nbsp;{r["ETime"] || ""}
                                        </p>
                                    )
                                } else {
                                    return "";
                                }
                            }
                        }, {
                            title: "Create Time",
                            dataIndex: 'CreateTime',
                            width: 150,
                            className: classes.df,
                        }, {
                            title: 'Action',
                            key: 'Action',
                            width: 150,
                            render: renderAction,
                        }]}
                        pagination={{
                            pageSizeOptions: [25, 50, 100],
                            current: pageNo + 1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => { return `Total ${total}` },
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page - 1);
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
                            getCheckboxProps: (r) => {
                                if (r.BurningCategory === "1" && r.ViaMaster !== "0") {
                                    return {
                                        disabled: true,
                                    }
                                }

                                return null;
                            }
                        }}
                    />
                </div>
            </div>
            <BurningAddGeneral show={showAddGeneral} onClose={handleAddingClose} />
            <BurningAddGeneralPassThrough show={showAddGeneralPassThrough} onClose={handleAddingClose} />
            <BurningAddEnergyPortPassthrough show={showAddEnergyPortPassthrough} onClose={handleAddingClose} />
            <BurningAddMasterControl show={showAddMasterControl} onClose={handleAddingClose} />
            {controlBurningInfo !== null ? (
                <BurningController
                    show={controlBurningInfo !== null}
                    sn={controlBurningInfo.SN}
                    deviceID={controlBurningInfo.ID}
                    burningID={controlBurningInfo.BurningID}
                    firmwareID={controlBurningInfo.FirmwareID}
                    dataLength={controlBurningInfo.DataLength}
                    onClose={() => { setControlBurningInfo(null); }}
                />
            ) : ""}
        </div>
    );
}

export default BurningManagement;