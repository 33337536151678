import { useState } from 'react';
import Voltage from './Curve/Voltage';
import Charge from './Curve/Charge';
import Discharge from './Curve/Discharge';
import { Button,Typography } from "@material-ui/core";


function Curve (props) {
    const { setTip, deviceID, deviceSN } = props;

    const [pageIndex, setPageIndex] = useState(0);

    return (
        <div style={{
            flex: "1 0 1px",
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{
                flex: "0 0 80px",
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
            }}>
                <div style={{
                    flex: "1 1 auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: '1px solid #888'
                }}>
                    <Typography style={{ color: '#eee', fontSize:30}}>{deviceSN}</Typography>
                </div>
            </div>
            <div style={{
                flex: '1 0 1px',
                display: "flex",
                alignItems: "stretch",
            }}>
                {pageIndex == 0 ? (
                    <Voltage 
                        setTip= {setTip}
                        deviceID= {deviceID}
                        deviceSN= {deviceSN}
                    />
                ) : pageIndex == 1 ? (
                    <Charge 
                        setTip= {setTip}
                        deviceID= {deviceID}
                        deviceSN= {deviceSN}
                    />
                ) : (
                    <Discharge 
                        setTip= {setTip}
                        deviceID= {deviceID}
                        deviceSN= {deviceSN}
                    />
                )}
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Button 
                    onClick={()=>{
                        if (pageIndex == 0) {
                            return;
                        }

                        setPageIndex(0);
                    }}
                    variant="contained"
                    style={{
                        color: pageIndex==0 ? "white" : "black",
                        backgroundColor: pageIndex==0 ? "black" : "transparent",
                        flex: '1 1 auto'
                    }}
                >Voltage</Button>
                <Button
                    onClick={()=>{
                        if (pageIndex == 1) {
                            return;
                        }

                        setPageIndex(1);
                    }}
                    variant="contained"
                    style={{
                        color: pageIndex==1 ? "white" : "black",
                        backgroundColor: pageIndex==1 ? "black" : "transparent",
                        flex: '1 1 auto'
                    }}
                >Charge</Button>
                <Button
                    onClick={()=>{
                        if (pageIndex == 2) {
                            return;
                        }

                        setPageIndex(2);
                    }}
                    variant="contained"
                    style={{
                        color: pageIndex==2 ? "white" : "black",
                        backgroundColor: pageIndex==2 ? "black" : "transparent",
                        flex: '1 1 auto'
                    }}
                >Discharge</Button>
            </div>
        </div>
    )
}

export default Curve;