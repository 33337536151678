import http from '../Http';
import { useContext, useState, useEffect, useRef } from 'react';
import utils from '../utils';
import timezone from '../utils/timezone'
import { Modal, Button, Input, Form, Radio, Switch, TreeSelect, Select } from 'antd';
import { EnvironmentOutlined  } from '@ant-design/icons';
import GPSGetter from "./GPSGetter"

const { TreeNode } = TreeSelect;

function Modifier (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose, data} = props;

    const [pDistributors, setPDistributors] = useState([]);
    const [showGPSGetter, setShowGPSGetter] = useState(false);
    const [originPID, setOriginPID] = useState(data&&data["PID"] ? data["PID"] : "");
    const [originPName, setOriginPName] = useState(data&&data["PName"] ? data["PName"] : "");
    const [timezoneSelectOptions, setTimezoneSelectOptions] = useState([]);

    const refTimerTimezoneSearcher = useRef(null);
    const refTimezoneSearchValue = useRef(null);
    

    const [form] = Form.useForm();

    const showInfoOnly = props.showInfoOnly ? true : false;  //从dashboard查看distributor信息时设置disabled为true

    const latRef = useRef();
    const lngRef = useRef();

    const [centerPoint, setCenterPoint] = useState([])
    const [centerPin, setCenterPin] = useState([])

    useEffect( async () => {
        let active = true;

        (async () => {
            try {
                const resp = await http.post("distributor/management/tree/get", {
                    //
                }).then(async (r)=>{
                    return await r.json();
                }).catch((e)=>{
                    console.log("[Modifier]", e);
                    return null;
                });

                if (! active) {
                    return;
                }
                
                if (resp === null || resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                    
                    setPDistributors([])
            
                    return;
                }
                setPDistributors(resp.data);

            } catch (e) {
                console.log("[Modifier]", e);
            }

            
        })();

        return () => {
            active = false;
        };
    }, [])


    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        ps.ID = data.ID;
        ps.Enabled = ps.Enabled ? 1 : 0;
        ps.PID = ps.PID || "";
        ps.Name = ps.Name || "";
        ps.Phone = ps.Phone || "";
        ps.EMail = ps.EMail || "";
        ps.Address = ps.Address || "";
        ps.Latitude *= 1000000;
        ps.Longitude *= 1000000;
        if (ps.PID === originPName) {
            ps.PID = originPID
        }
        

        const resp = await http.post("distributor/management/modify", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[Modifier]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps.Category = ps.Category.toString();
            if (ps.PID) {
                let PName = "";
                const qs = []
                pDistributors.every((v) => {
                    if (v.ID === ps.PID) {
                        PName = v.Name;
                        return false;
                    }

                    if (v.children) {
                        qs.push(v.children);
                    }

                    return true;
                });
                if (PName === "" && qs.length > 0) {
                    while (qs.length > 0) {
                        const ds = qs.pop();
                        ds.every((v) => {
                            if (v.ID === ps.PID) {
                                PName = v.Name;
                                return false;
                            }

                            if (v.children) {
                                qs.push(v.children);
                            }

                            return true;
                        });

                        if (PName !== "") {
                            break;
                        }
                    }
                }
                ps.PName = PName;
            }
            
            onClose(ps);
        } catch (e) {
            console.log("[Modifier]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    const generateTree = (ds, disabled) => {
        if (! ds) {
            return null;
        }

        if (disabled) {
            disabled = true;
        } else {
            disabled = false;
        }

        return ds.map((d) => {
            let dis = disabled;
            if (dis === false) {
                if (data && d.ID === data.ID) {
                    dis = true;
                }
            }
            if (d.children) {
                return (<TreeNode key={d.ID} disabled={dis} value={d.ID} title={d.Name}>{generateTree(d.children, dis)}</TreeNode>)
            } else {
                return <TreeNode key={d.ID} disabled={dis} value={d.ID} title={d.Name} />
            }
        })
    }

    const handleGetGPS = () => {
        setShowGPSGetter(true);
        const lat = latRef.current.props.value;
        const lng = lngRef.current.props.value;
        setCenterPoint([lat, lng])
        setCenterPin([{ "location": [lat, lng] }])
    }

    const handleGPSGetterClose = (location) => {
        setShowGPSGetter(false);

        if (location) {
            const lat = parseFloat(location.Latitude.toFixed(6));
            const lng = parseFloat(location.Longitude.toFixed(6));

            form.setFieldsValue({Latitude:lat, Longitude:lng});
        }
    }

    const param = {...data};
    param.Address = param.Address || "";
    param.EMail = param.EMail || "";
    param.EMail = param.EMail || "";
    param.Latitude = param.Latitude ? param.Latitude/1000000 : "";
    param.Longitude = param.Longitude ? param.Longitude/1000000 : "";
    param.MainAccountID = param.MainAccountID || "";
    param.Phone = param.Phone || "";
    param.Enabled = param.Enabled ? parseInt(param.Enabled) : 0 ;
    param.Timezone = param.Timezone ? param.Timezone : "" ;
    param.Category = param.Category ? parseInt(param.Category) : 0 ;
    if (param.PID) {
        param.PID = param.PName;
    }

    const handleSearchTimezone = (v) => {
        if (refTimerTimezoneSearcher.current != null) {
            clearTimeout(refTimerTimezoneSearcher.current);
        }
        refTimezoneSearchValue.current = v;


        refTimerTimezoneSearcher.current = setTimeout(async () => {
            const resp = await http.post("timezone/list", {
                Timezone: v,
            }).then(async (r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refTimezoneSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({ open: true, severity: "error", msg: (resp && resp.msg) ? resp.msg : "load timezone list error" });
                    return;
                }

                const ls = resp.data.map(({ ZoneName, GmtOffset }) => {
                    var n = parseInt(GmtOffset);
                    var tz = "UTC+";
                    if (n < 0) {
                        tz = "UTC-";
                        n = Math.abs(n);
                    }
                    tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                    return (
                        <Select.Option key={ZoneName} value={ZoneName}>{ZoneName}({tz})</Select.Option>
                    );
                });
                setTimezoneSelectOptions(ls);
            } catch (e) {
                console.log("[Distributor-Adder]", e);

                setTip({ open: true, severity: "error", msg: "load device list error" });
            }
        }, 300);
    }


    return (<Modal 
        title={showInfoOnly ? "Distributor Information" : "Add Distributor"}
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                onClick={() => {
                    form.resetFields();
                }}
                disabled={showInfoOnly}
            >Clear</Button>,
            <Button key="Cancel" onClick={handleCancle} disabled={showInfoOnly}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit} disabled={showInfoOnly}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            form={form}
            initialValues={param}
        >
            <Form.Item 
                name="PID"
                label="Superior Distributor"
                rules={[{ required: false }]}
            >
                <TreeSelect 
                    placeholder="Superior Distributor "
                    defaultActiveFirstOption={true}
                    showArrow={true}
                    filterOption={false}
                    treeDefaultExpandAll
                    disabled={showInfoOnly}
                >{generateTree(pDistributors)}</TreeSelect>
            </Form.Item>
            <Form.Item 
                name="Name"
                label="Name"
                rules={[{ required: true }]}
            >
                <Input disabled={showInfoOnly} />
            </Form.Item>
            <Form.Item 
                name="Category"
                label="Category"
                rules={[{ required: true }]}
            >
                <Radio.Group name="Category" disabled>
                    <Radio value={0}>Distributor</Radio>
                    <Radio value={1}>Installer</Radio>
                    <Radio value={2}>Manufacturer</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item 
                name="MainAccountID"
                label="MainAccountID"
                rules={[{ required: true, min: 3 }]}
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item 
                name="Timezone"
                label="Timezone"
                rules={[{ required: true, type: 'string' }]}
            >
                <Select
                    showSearch
                    placeholder="Timezone"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchTimezone}
                >{timezoneSelectOptions}</Select>
            </Form.Item>
            <Form.Item 
                name="Phone"
                label="Phone"
            >
                <Input placeholder="Phone Number" disabled={showInfoOnly}/>
            </Form.Item>
            <Form.Item 
                name="EMail"
                label="E-Mail"
            >
                <Input disabled={showInfoOnly} />
            </Form.Item>
            <Form.Item 
                name="Address"
                label="Address"
            >
                <Input disabled={showInfoOnly} />
            </Form.Item>
            <Form.Item 
                name="Enabled"
                label="Enabled"
                valuePropName="checked"
            >
                <Switch disabled={showInfoOnly} />
            </Form.Item>
            <Form.Item 
                label="GPS"
            >
                <Form.Item 
                    name="Longitude"
                    rules={[{ required: true, type: 'number' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)' }}
                >
                    <Input ref={lngRef} placeholder="Logitude, e.g.: 118.123456" disabled={showInfoOnly}/>
                </Form.Item>
                <Form.Item 
                    name="Latitude"
                    rules={[{ required: true, type: 'number' }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 16px)' }}
                >
                    <Input ref={latRef} placeholder="Latitude, e.g.: 31.123456" disabled={showInfoOnly}/>
                </Form.Item>
                <Button onClick={handleGetGPS} icon={<EnvironmentOutlined />} disabled={showInfoOnly}/>
            </Form.Item>
        </Form>
        <GPSGetter centerPoint={centerPoint} centerPin={centerPin} show={showGPSGetter} onClose={handleGPSGetterClose} />
    </Modal>);
}

export default Modifier;