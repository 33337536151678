import { useState, useEffect, useRef } from 'react';
import { Typography } from "@material-ui/core";
import utils from "../utils";
import ReactECharts from 'echarts-for-react';

let _rate = 1;
let _paddingHorizontal = 0;

const _option = {
    backgroundColor: '#1b1b1b',
    tooltip: {
        formatter: '{a} <br/>{c} {b}'
    },
    toolbox: {
        show: true,
        feature: {
            mark: {show: true},
            restore: {show: true},
            saveAsImage: {show: true}
        },
    },
    xAxis: {
        show: false,
    },
    yAxis: {
        show: false,
    },
    series: [
        {
            name: 'SOC',
            type: 'gauge',
            center: [134.161, 55],
            min: 0,
            max: 100,
            splitNumber: 10,
            radius: 55,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0.09, '#ff4500'], [0.30, '#1e90ff'], [1, 'lime']],
                    width: 3,
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            axisLabel: {            // 坐标轴小标记
                fontWeight: 'bolder',
                color: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 10,
                distance: 8,
            },
            axisTick: {            // 坐标轴小标记
                length: 10,        // 属性length控制线长
                lineStyle: {       // 属性lineStyle控制线条样式
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 5
                },
            },
            splitLine: {           // 分隔线
                length: 16,         // 属性length控制线长
                lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                    width: 3,
                    color: '#fff',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            pointer: {           // 分隔线
                itemStyle: {
                    shadowColor: '#fff',
                    color: 'auto',
                },
                shadowBlur: 5,
                length: '40%',
                offsetCenter: [0, '-40%'],
            },
            title: {
                show: false,
            },
            detail: {
                shadowColor: '#fff', //默认透明
                shadowBlur: 5,
                offsetCenter: [0, 0],       // x, y，单位px
                textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    fontWeight: 'bolder',
                    fontSize: 12,
                    color: 'auto',
                    lineHeight: 12,
                },
                show: true,
                formatter: (v) => {
                    return "SOC\n"+v.toFixed(1)+"%";
                }
            },
            data: [{value: 0, name: '%'}]
        },
        {
            name: 'Current',
            type: 'gauge',
            center: [50, 65],    // 默认全局居中
            radius: 50,
            min: -100,
            max: 100,
            endAngle: 45,
            splitNumber: 5,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0.29, '#ff4500'], [0.86, 'lime'], [1, '#ff4500']],
                    width: 2,
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10,
                }
            },
            axisLabel: {            // 坐标轴小标记
                fontWeight: 'bolder',
                color: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 10,
                distance: 8,
            },
            axisTick: {            // 坐标轴小标记
                length: 12,        // 属性length控制线长
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: 'auto',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                },
                show: true,
            },
            splitLine: {           // 分隔线
                length: 16,         // 属性length控制线长
                lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                    width: 3,
                    color: '#fff',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            pointer: {
                width: 2,
                itemStyle: {
                    shadowColor: '#fff',
                    color: 'auto',
                },
                shadowBlur: 5,
                length: '40%',
                offsetCenter: [0, '-40%'],
            },
            title: {
                show: false,
            },
            detail: {
                borderColor: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 5,
                width: 50,
                height: 30,
                offsetCenter: [10, 0],       // x, y，单位px
                textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    fontWeight: 'bolder',
                    color: 'auto',
                    fontSize: 12,
                    lineHeight: 12,
                },
                formatter: (v) => {
                    return "Current\n"+v.toFixed(1)+"A";
                }
            },
            data: [{value: 0, name: 'A'}]
        },
        {
            name: 'Voltage',
            type: 'gauge',
            center: [218.322, 65],    // 默认全局居中
            radius: 50,
            min: 0,
            max: 60,
            startAngle: 135,
            endAngle: -45,
            splitNumber: 6,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0.80, '#ff4500'], [0.97, 'lime'], [1, '#ff4500']],
                    width: 2,
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10,
                    distance: 8,
                }
            },
            axisTick: {            // 坐标轴小标记
                length: 12,        // 属性length控制线长
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: 'auto',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            axisLabel: {
                fontWeight: 'bolder',
                color: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 10,
                distance: 8,
            },
            splitLine: {           // 分隔线
                length:15,         // 属性length控制线长
                lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                    width:3,
                    color: '#fff',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            pointer: {
                width:2,
                itemStyle: {
                    shadowColor: '#fff', //默认透明
                    color: 'auto',
                },
                shadowBlur: 5,
                length: '40',
                offsetCenter: [0, '-40%']
            },
            title: {
                show: false
            },
            detail: {
                borderColor: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 5,
                width: 50,
                height: 30,
                offsetCenter: [0, 0],       // x, y，单位px
                textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    fontWeight: 'bolder',
                    color: 'auto',
                    fontSize: 12,
                    lineHeight: 12,
                },
                formatter: (v) => {
                    return "Voltage\n"+v.toFixed(1)+"V";
                }
            },
            data: [{value: 0, name: 'V'}]
        },
        {
            name: 'CellVoltage',
            type: 'gauge',
            center: [85.355, 136.504],    // 默认全局居中
            radius: 40,
            min: 0,
            max: 6,
            startAngle: 225,
            endAngle: -45,
            splitNumber: 6,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0.51, '#ff4500'], [0.6, 'lime'], [1, '#aaa']],
                    width: 2,
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10,
                    distance: 8,
                }
            },
            axisTick: {            // 坐标轴小标记
                length: 12,        // 属性length控制线长
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: 'auto',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            axisLabel: {
                fontWeight: 'bolder',
                color: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 10,
                distance: 8,
            },
            splitLine: {           // 分隔线
                length:15,         // 属性length控制线长
                lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                    width:3,
                    color: '#fff',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            pointer: {
                width:3,
                itemStyle: {
                    shadowColor: '#fff', //默认透明
                    color: 'auto',
                },
                shadowBlur: 5,
            },
            title: {
                show: false
            },
            detail: {
                borderColor: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 5,
                textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    fontWeight: 'bolder',
                    fontSize: 6,
                    lineHeight: 8,
                },
            },
            data: [{
                value: [0,0,0,0], //voltage,cluster,pack, index 
                name: 'V',
                detail: {
                    color: "#aaa",
                    offsetCenter: [0, '72%'],
                    formatter: (v) => {
                        return 'Cell Voltage';
                    }
                }
            },{
                value: [0,0,0,0], //voltage,cluster,pack, index 
                name: 'V',
                detail: {
                    color: "#ccc",
                    offsetCenter: [0, '100%'],
                    formatter: function (v) {
                        const minD = _option.series[3].data[0].value;
                        const maxD = _option.series[3].data[1].value;
                        const min = minD[0].toFixed(3);
                        return 'Min: '+min+'V, C:'+minD[1]+', P:'+minD[2]+', I:'+minD[3]+
                            '\n Max: '+(v).toFixed(3)+"V"+', C:'+maxD[1]+', p:'+maxD[2]+', I:'+maxD[3];
                    }
                }
            }]
        },
        {
            name: 'Temperature',
            type: 'gauge',
            center: [182.967, 136.504],    // 默认全局居中
            radius: 40,
            min: -50,
            max: 100,
            startAngle: 225,
            endAngle: -45,
            splitNumber: 5,
            axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: [[0.21, '#ff4500'], [0.28, '#1e90ff'], [0.75, 'lime'], [0.80, '#1e90ff'], [1, '#ff4500']],
                    width: 2,
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10,
                    distance: 8,
                }
            },
            axisTick: {            // 坐标轴小标记
                length: 12,        // 属性length控制线长
                lineStyle: {       // 属性lineStyle控制线条样式
                    color: 'auto',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            axisLabel: {
                fontWeight: 'bolder',
                color: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 10,
                distance: 8,
            },
            splitLine: {           // 分隔线
                length:15,         // 属性length控制线长
                lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                    width:3,
                    color: '#fff',
                    shadowColor: '#fff', //默认透明
                    shadowBlur: 10
                }
            },
            pointer: {
                width:3,
                itemStyle: {
                    color: 'auto',
                    shadowColor: '#fff', //默认透明
                },
                shadowBlur: 5,
                // length: '40',
                // offsetCenter: [0, '-30%']

            },
            title: {
                show: false,
            },
            detail: {
                borderColor: '#fff',
                shadowColor: '#fff', //默认透明
                shadowBlur: 5,
                textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    fontWeight: 'bolder',
                    fontSize: 6,
                    lineHeight: 8,
                },
            },
            data: [{
                value: [0,0,0,0], //voltage,cluster,pack, index 
                name: '℃',
                detail: {
                    color: "#aaa",
                    offsetCenter: [0, '72%'],
                    formatter: (v) => {
                        return 'Temperature';
                    }
                }
            },{
                value: [0,0,0,0], //voltage,cluster,pack, index  
                name: '℃',
                detail: {
                    color: "#ccc",
                    offsetCenter: [0, '100%'],
                    formatter: (v) => {
                        const minD = _option.series[4].data[0].value;
                        const maxD = _option.series[4].data[1].value;
                        const min = minD[0].toFixed(1);
                        return 'Min: '+min+'℃, C:'+minD[1]+', P:'+minD[2]+', I:'+minD[3]+
                            '\n Max: '+(v).toFixed(1)+"℃"+', C:'+maxD[1]+', p:'+maxD[2]+', I:'+maxD[3];
                    }
                }
            }]
        },
        {
            name: "Charge",
            type: 'custom',
            silent: true,
            renderItem: (params, api) => {
                return {
                    type: 'text',
                    style: api.style({
                        text: "Daily Charge: {value|"+api.value(0).toFixed(1)+"kWh}",
                        x: 32.1288*_rate+_paddingHorizontal,
                        y: 206.504*_rate
                    })
                }
            },
            data: [[0]],
            label: {
                normal: {
                    show: true,
                    borderColor: 'rgb(34,86,200)',
                    borderWidth: 2,
                    borderRadius: 5,
                    padding: 7,
                    color: '#aaa',
                    fontSize: 7,
                    shadowBlur: 3,
                    shadowColor: '#888',
                    shadowOffsetX: 0,
                    shadowOffsetY: 3,
                    lineHeight: 9,
                    width: 80,
                    height: 10,
                    rich: {
                        value: {
                            fontSize: 7,
                            color: '#fff'
                        },
                    }
                }
            }
        },
        {
            name: "Discharge",
            type: 'custom',
            silent: true,
            renderItem: (params, api) => {
                return {
                    type: 'text',
                    style: api.style({
                        text: "Daily Discharge: {value|"+api.value(0).toFixed(1)+"kWh}",
                        x: 144.193*_rate+_paddingHorizontal,
                        y: 206.504*_rate
                    })
                }
            },
            data: [[0]],
            label: {
                normal: {
                    show: true,
                    borderColor: 'rgb(34,86,200)',
                    borderWidth: 2,
                    borderRadius: 5,
                    padding: 7,
                    color: '#aaa',
                    fontSize: 7,
                    shadowBlur: 3,
                    shadowColor: '#888',
                    shadowOffsetX: 0,
                    shadowOffsetY: 3,
                    lineHeight: 9,
                    width: 80,
                    height: 10,
                    rich: {
                        value: {
                            fontSize: 7,
                            color: '#fff'
                        },
                    }
                }
            }
        },
        {
            name: "PackStatus",
            type: 'custom',
            silent: true,
            renderItem: (params, api) => {
                const packStatuses = {
                    0: "static",
                    1: "charge",
                    2: "discharge",
                    3: "fault",
                    4: "sleep"
                };
                return {
                    type: 'text',
                    style: api.style({
                        text: "Pack Status: {value|"+(packStatuses[api.value(0)]||'-')+"}",
                        x: 32.1288*_rate+_paddingHorizontal,
                        y: 240.504*_rate
                    })
                }
            },
            data: [[0]],
            label: {
                normal: {
                    show: true,
                    borderColor: 'rgb(34,86,200)',
                    borderWidth: 2,
                    borderRadius: 5,
                    padding: 7,
                    color: '#aaa',
                    fontSize: 7,
                    shadowBlur: 3,
                    shadowColor: '#888',
                    shadowOffsetX: 0,
                    shadowOffsetY: 3,
                    lineHeight: 9,
                    width: 80,
                    height: 10,
                    rich: {
                        value: {
                            fontSize: 7,
                            color: '#fff'
                        },
                    }
                }
            }
        },
        {
            name: "SDStatus",
            type: 'custom',
            silent: true,
            renderItem: (params, api) => {
                const packStatuses = {
                    0: "no-inserted",
                    1: "inserted",
                };
                return {
                    type: 'text',
                    style: api.style({
                        text: "SD Status: {value|"+(packStatuses[api.value(0)]||'-')+"}",
                        x: 144.193*_rate+_paddingHorizontal,
                        y: 240.504*_rate
                    })
                }
            },
            data: [[0]],
            label: {
                normal: {
                    show: true,
                    borderColor: 'rgb(34,86,200)',
                    borderWidth: 2,
                    borderRadius: 5,
                    padding: 7,
                    color: '#aaa',
                    fontSize: 7,
                    shadowBlur: 3,
                    shadowColor: '#888',
                    shadowOffsetX: 0,
                    shadowOffsetY: 3,
                    lineHeight: 9,
                    width: 80,
                    height: 10,
                    rich: {
                        value: {
                            fontSize: 7,
                            color: '#fff'
                        },
                    }
                }
            }
        },
    ],
};

const design = {
    width: 268.322,
    height: 269.504,
    padding: 10,
    soc: {
        r: 55,
        x: 134.161,
        y: 55,
    },
    current: {
        r: 50,
        x: 50,
        y: 65
    },
    voltage: {
        r: 50,
        x: 218.322,
        y:65,
    },
    cellVoltage: {
        r: 40,
        x: 85.355,
        y: 136.504,
        detail: {
            fontSize: 6,
            lineHeight: 8,
        }
    },
    temperature: {
        r: 40,
        x: 182.967,
        y: 136.504,
        detail: {
            fontSize: 6,
            lineHeight: 8,
        }
    },
    other: {
        width: 80,
        height:10,
        label: {
            fontSize: 7,
            lineHeight: 9,
            padding: 7,
        }
    }
}

function DeviceSummary(props) {
    const {setTip, data} = props;

    const chartEl = useRef(null);
    const chart = useRef(null);
    
    const option = useRef({});

    useEffect(() => {
        console.log("useEffect[chartEl]", chartEl)

        let w = chartEl.current.offsetWidth - design.padding*2;
        let h = chartEl.current.offsetHeight - design.padding*2;
        let opt = option.current;
        if (! opt.series) {
            opt = _option;
        }

        let h2 = w*design.height / design.width;
        let w2 = h*design.width / design.height;
        if (h2 > h) {
            h2 = h;
            _paddingHorizontal = (w-w2) /2;
        } else if (w2 > w) {
            w2 = w;
        }
        let rate = w2 / design.width;
        _rate = rate;

        //SOC Shape
        let s = opt.series[0];
        let d = design.soc;
        s.center = [d.x*rate+design.padding+_paddingHorizontal, d.y*rate+design.padding];
        s.radius = d.r*rate;
        //Current Shape
        s = opt.series[1];
        d = design.current;
        s.center = [d.x*rate+design.padding+_paddingHorizontal, d.y*rate+design.padding];
        s.radius = d.r*rate;
        //Voltage Shape
        s = opt.series[2];
        d = design.voltage;
        s.center = [d.x*rate+design.padding+_paddingHorizontal, d.y*rate+design.padding];
        s.radius = d.r*rate;
        //CellVoltage Shape
        s = opt.series[3];
        d = design.cellVoltage;
        s.center = [d.x*rate+design.padding+_paddingHorizontal, d.y*rate+design.padding];
        s.radius = d.r*rate;
        s.detail.textStyle.fontSize = d.detail.fontSize*rate;
        s.detail.textStyle.lineHeight = d.detail.lineHeight*rate;
        //Temperature Shape
        s = opt.series[4];
        d = design.temperature;
        s.center = [d.x*rate+design.padding+_paddingHorizontal, d.y*rate+design.padding];
        s.radius = d.r*rate;
        s.detail.textStyle.fontSize = d.detail.fontSize*rate;
        s.detail.textStyle.lineHeight = d.detail.lineHeight*rate;
        //Charge Shape
        d = design.other;
        s = opt.series[5];
        s.label.normal.width = d.width*rate;
        s.label.normal.height = d.height*rate;
        s.label.normal.fontSize = d.label.fontSize*rate;
        s.label.normal.lineHeight = d.label.lineHeight*rate;
        s.label.normal.rich.value.fontSize = d.label.fontSize*rate;
        //Discharge Shape
        s = opt.series[6];
        s.label.normal.width = d.width*rate;
        s.label.normal.height = d.height*rate;
        s.label.normal.fontSize = d.label.fontSize*rate;
        s.label.normal.lineHeight = d.label.lineHeight*rate;
        s.label.normal.rich.value.fontSize = d.label.fontSize*rate;
        //Pack Status Shape
        s = opt.series[7];
        s.label.normal.width = d.width*rate;
        s.label.normal.height = d.height*rate;
        s.label.normal.fontSize = d.label.fontSize*rate;
        s.label.normal.lineHeight = d.label.lineHeight*rate;
        s.label.normal.rich.value.fontSize = d.label.fontSize*rate;
        //SD Status Shape
        s = opt.series[8];
        s.label.normal.width = d.width*rate;
        s.label.normal.height = d.height*rate;
        s.label.normal.fontSize = d.label.fontSize*rate;
        s.label.normal.lineHeight = d.label.lineHeight*rate;
        s.label.normal.rich.value.fontSize = d.label.fontSize*rate;
        
        option.current = opt;
    }, [chartEl]);

    const opt = option.current;
    if (opt.series) {
        //SOC Value
        let s = opt.series[0];
        s.data = [{...s.data[0], value: (data ? (data["SOC"]/10): 0)}];
        //Current Value
        s = opt.series[1];
        s.data = [{...s.data[0], value: (data ? ((data["Current"]-32767)/10): 0)}];
        //Voltage Value
        s = opt.series[2];
        s.data = [{...s.data[0], value: (data ? (data["Voltage"]/10): 0)}];
        //CellVoltage Value
        s = opt.series[3];
        s.data[0].value[0] = data ? (data["MinCellVoltage"]/1000): 0;
        s.data[0].value[1] = data ? data["MinCVCluster"]: 0;
        s.data[0].value[2] = data ? data["MinCVPacket"]: 0;
        s.data[0].value[3] = data ? data["MinCVIndex"]: 0;
        s.data[1].value[0] = data ? (data["MaxCellVoltage"]/1000): 0;
        s.data[1].value[1] = data ? data["MaxCVCluster"]: 0;
        s.data[1].value[2] = data ? data["MaxCVPacket"]: 0;
        s.data[1].value[3] = data ? data["MaxCVIndex"]: 0;
        //Temperature Value
        s = opt.series[4];
        s.data[0].value[0] = data ? ((data["MinT"]-50)): 0;
        s.data[0].value[1] = data ? data["MinTCluster"]: 0;
        s.data[0].value[2] = data ? data["MinTPacket"]: 0;
        s.data[0].value[3] = data ? data["MinTIndex"]: 0;
        s.data[1].value[0] = data ? ((data["MaxT"]-50)): 0;
        s.data[1].value[1] = data ? data["MaxTCluster"]: 0;
        s.data[1].value[2] = data ? data["MaxTPacket"]: 0;
        s.data[1].value[3] = data ? data["MaxTIndex"]: 0;
        //Charge Value
        s = opt.series[5];
        s.data[0][0] = data ? (data["DailyCharging"]/10): 0;
        //Discharge Value
        s = opt.series[6];
        s.data[0][0] = data ? (data["DailyDischarging"]/10): 0;
        //Pack Satus Value
        s = opt.series[7];
        s.data[0][0] = data ? (data["Status"]/10): 0;
        //SD Satus Value
        s = opt.series[8];
        s.data[0][0] = data ? (data["SDStatus"]/10): 0;

        option.current = opt;
    }

    useEffect(() => {
        const instance = chart.current.getEchartsInstance();
        if (instance) {
            instance.setOption(option.current);
        }
    }, [data])

    return (
        <div style={{
            flex: "1 0 1px",
            backgroundColor: "#666",
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                flex: "0 0 36px",
                backgroundColor: "black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Typography style={{color:"#eee"}}>Summary</Typography>
                <Typography style={{color:"#aaa", fontSize:12}}>{data ? utils.dateFormat("YYYY-mm-dd HH:MM:SS", new Date()) : "yyyy-mm-dd hh:mm:ss"}</Typography>
            </div>
            <div style={{
                flex: "1, 1 1px",
                minWidth: 400,
            }} ref={chartEl}>
                <ReactECharts ref={chart} option={opt} style={{height: 500}}/>
            </div>
        </div>
    )
}

export default DeviceSummary;