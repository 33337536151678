import http from '../Http';
import { useContext, useState, useRef } from 'react';
import utils from '../utils';
import { Modal, Button, Input, Form, Select, Switch } from 'antd';


function DistributorUserAdder (props) {
    const setTip = useContext(utils.tipContext);

    const {show, onClose} = props;

    const [form] = Form.useForm();

    const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);

    const refTimerDistributorSearcher = useRef(null);
    const refDistributorSearchValue = useRef(null);

    const handleCancle = () => {
        onClose(null);
    }

    const handleSubmit = async () => {
        const ps = await form.validateFields().catch(() => {return null});
        
        if (ps === null) {
            return;
        }

        ps.Enabled = ps.Enabled ? 1 : 0;
        ps.Name = ps.Name || "";
        ps.Phone = ps.Phone || "";
        ps.EMail = ps.EMail || "";
        ps.Address = ps.Address || "";
        

        const resp = await http.post("user/management/distributor-user/add", ps).then(async (r)=>{
            return await r.json();
        }).catch((e) => {
            console.log("[UserAdd]", e);
            return null;
        });

        try {
            if (resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "add error"});
                return
            }

            setTip({open: true, severity: 'success', msg: 'add success'});

            ps.CreateTime = resp.data.CreateTime;
            ps.DistributorName = (resp.data.DistributorName || "");
            ps.Timezone = (resp.data.Timezone || "");
            ps.GmtOffset = (resp.data.GmtOffset || "");
            ps.Role="DistributorUser";
            onClose(ps);
        } catch (e) {
            console.log("[UserAdd]", e);

            setTip({open:true, severity:"error", msg: "add error"});
        }
    }

    const handleSearchDistributor = (v) => {
        if (refTimerDistributorSearcher.current != null) {
            clearTimeout(refTimerDistributorSearcher.current);
        }
        refDistributorSearchValue.current = v;

        refTimerDistributorSearcher.current = setTimeout(async () => {
            const resp = await http.post("distributor/name/list", {
                Value: v,
            }).then(async(r) => {
                return await r.json();
            }).catch((e) => {
                console.log("[DeviceAdd]", e);
                return null;
            });

            if (refDistributorSearchValue.current !== v) {
                return;
            }

            try {
                if (resp.code !== 200) {
                    setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load distributor list error"});
                    return;
                }

                const ls = resp.data.map(({Name, ID}) => {
                    return (
                        <Select.Option key={ID} value={ID}>{Name}</Select.Option>
                    );
                });
                setDistributorSelectOptions(ls);
            } catch(e) {
                console.log("[DeviceAdd]", e);

                setTip({open:true, severity:"error", msg: "load distributor list error"});
            }
        }, 300);
    }

    return (<Modal 
        title="Add Distributor User"
        visible={show}
        cancelText="Cancel"
        okText="Submit"
        mask={true}
        maskClosable={false}
        width={600}
        onCancel={handleCancle}
        onOk={handleSubmit}
        footer={[
            <Button 
                key="Clear" 
                onClick={() => {
                    form.resetFields();
                }}
            >Clear</Button>,
            <Button key="Cancel" onClick={handleCancle}>Cancel</Button>,
            <Button key="Submit" type="primary" onClick={handleSubmit}>Submit</Button>
        ]}
    >
        <Form
            labelAlign="right"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            form={form}
            initialValues={{
                Enabled: true,
            }}
        >
            <Form.Item 
                name="DistributorID"
                label="Distributor"
                rules={[{ required: true }]}
            >
                <Select 
                    showSearch
                    placeholder="Enter Distributor Name"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchDistributor}
                >{distributorSelectOptions}</Select>
            </Form.Item>
            <Form.Item 
                name="Name"
                label="Title"
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="ID"
                label="UserID"
                rules={[{ required: true, min: 3 }]}
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Password"
                label="Password"
                rules={[{ required: true, min: 6 }]}
            >
                <Input placeholder="Login Password"/>
            </Form.Item>
            <Form.Item 
                name="Phone"
                label="Phone"
            >
                <Input placeholder="Phone Number"/>
            </Form.Item>
            <Form.Item 
                name="EMail"
                label="E-Mail"
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Address"
                label="Address"
            >
                <Input />
            </Form.Item>
            <Form.Item 
                name="Enabled"
                label="Enabled"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
        </Form>
    </Modal>);
}

export default DistributorUserAdder;