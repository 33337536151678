import http from '../Http';
import { useEffect, useState, useContext, useRef } from 'react';
import utils from '../utils';
import { Table, Input, Button, Modal, Form, Row, Col, Checkbox, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, CheckOutlined, CloseOutlined, UpOutlined, DownOutlined, UserAddOutlined, PlusOutlined  } from '@ant-design/icons';
import Adder from "./Adder";
import Modifier from "./Modifier";

function DistributorManagement(props) {
    const setTip = useContext(utils.tipContext);

    const [pageNo, setPageNo] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [params, setParams] = useState({
        DistributorName: "",
        Categories: "",
        Enabled: ""
    });
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowTotal, setRowTotal] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(240);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [modifyRowIndex, setModifyRowIndex] = useState(null);
    const [showAdder, setShowAdder] = useState(false);
    const [showModifier, setShowModifier] = useState(false);

    const refTableContainer = useRef();

    const [form] = Form.useForm();

    useEffect(() => {
        let active = true;
        console.log("DistributorManagement Effect");

        (async () => {
            setLoading(true);
            
            let resp = await http.post("distributor/management/list", {
                PageNo: pageNo,
                PageSize: pageSize,
                ...params,
            }).then(async (r)=>{
                return await r.json();
            }).catch((e)=>{
                console.log("[DistributorManagement]", e);
                return null;
            });

            if (! active) {
                return;
            }
            
            if (resp === null || resp.code !== 200) {
                setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "load data error"});
                
                setRowTotal(0)
                setRows([]);
        
                return;
            }
            setRowTotal(resp.data.RowTotal);
            setRows(resp.data.Rows);
            setLoading(false);
        })();

        return () => {
            active = false;
            console.log("DistributorManagement Effect Cancel");
        };
    }, [pageNo, pageSize, params]);

    useEffect(() => {
        const el = refTableContainer.current;

        if (el.objResizer) {
            return;
        }
        
        let obj = document.createElement('object');
        el.objResizer = obj; 
        obj.setAttribute('style',
            'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;');
        obj.onload = function () {
            setTableBodyHeight(calcTableBodyHeight());

            this.contentDocument.defaultView.addEventListener('resize', (evt) => {
                setTableBodyHeight(calcTableBodyHeight());
            });
        };
        obj.type = 'text/html';
        el.appendChild(obj);
        obj.data = 'about:blank';

        return () => {
            if (! el) {
                return
            }

            //
        }
    }, [refTableContainer.current]);

    const calcTableBodyHeight = () => {
        if (! refTableContainer.current) {
            return 240;
        }

        const hd = document.getElementsByClassName("ant-table-thead")[0];

        const h = refTableContainer.current.getBoundingClientRect().bottom - hd.getBoundingClientRect().bottom;

        return  h>=54 ? h - 54 : h /2;
    }

    const renderAction = (v, r, idx) => {
        return (
            <>
                <Button onClick={()=>{handleModify(idx)}} type="link" icon={<EditOutlined  size="small"/>} />
                <Button onClick={()=>{handleDelete(r.ID, r.Name, idx)}} type="link" icon={<DeleteOutlined  size="small"/>} />
            </>
        )
    }

    const handleSearch = () => {
        setSelectedKeys([]);
        setPageNo(0);

        const ps = form.getFieldsValue();
        ps.DistributorID = ps.UserID || "";
        ps.DistributorName = ps.DistributorName || "";
        ps.Categories = (ps.Categories || []).join(",");
        ps.Enabled = ps.Enabled ? (ps.Enabled.indexOf("Enabled")>-1 && ps.Enabled.indexOf("Disabled")> -1 ? "" : ps.Enabled.indexOf("Enabled")>-1 ? 1 : 0) : "";
        setParams(ps);
    }

    const handleSelect = (selectedRowKeys, selectedRows) => {
        setSelectedKeys([...selectedRowKeys]);
    }

    const handleAdd = () => {
        setShowAdder(true);
    }

    const handleAdderClose = (row) => {
        setShowAdder(false);
        setShowModifier(false);

        if (! row) {
            return;
        }

        document.getElementsByClassName("ant-table-body")[0].scrollTo(0, 0);
        setSelectedKeys([row.ID]);
        setRows([row, ...rows]);
        setRowTotal(rowTotal+1);
    }

    const handleModify = (rowIndex) => {
        setModifyRowIndex(rowIndex);
        //setShowModify(true);
        const row = rows[rowIndex];
        setShowModifier(true)
    }


    const handleModifierClose = (row) => {
        setShowAdder(false);
        setShowModifier(false);
        setModifyRowIndex(null);

        if (! row) {
            return;
        }

        setSelectedKeys([row.ID]);
        const rw = {...rows[modifyRowIndex], ...row}
        setRows([...rows.slice(0, modifyRowIndex), rw, ...rows.slice(modifyRowIndex+1)]);
    }

    const handleDelete = async (ID, Name, idx) => {
        Modal.confirm({
            title: 'Do you Want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: Name,
            onOk: async () => {
                try {
                    const resp = await http.post("distributor/management/delete", {DistributorIDs: ID}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[DistributorManagement]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    setRows([...rows.slice(0,idx), ...rows.slice(idx+1)]);
                    setRowTotal(rowTotal-1);
                    selectedKeys.every((v,i) => {
                        if (v === ID) {
                            setSelectedKeys([...selectedKeys.slice(0,i), ...selectedKeys.slice(i+1)]);
                            return false;
                        }
        
                        return true;
                    })
                } catch(e) {
                    console.log("[DistributorManagement]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleDeleteAllOfSelected = async () => {
        if (selectedKeys.length < 1) {
            return;
        }

        let ns = [];
        selectedKeys.forEach((ID) => {
            rows.every((r) => {
                if (ID === r.ID) {
                    ns.push(<label>{r.Name}</label>);
                    return false;
                }

                return true;
            });
        })
        if (ns.length > 10) {
            ns = ns.slice(0, 10);
            ns.push(<lable>...</lable>);
        }

        Modal.confirm({
            title: 'Do you Want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: (<div style={{display:'flex', flexDirection:'column'}}>{ns}</div>),
            onOk: async () => {
                try {
                    const resp = await http.post("distributor/management/multi/delete", {DistributorIDs: selectedKeys.join(",")}).then(async(r) => {
                        return await r.json();
                    }).catch((e) => {
                        console.log("[DistributorManagement]", e);
                        return null;
                    });
        
                    if (resp.code !== 200) {
                        setTip({open:true, severity:"error", msg:(resp && resp.msg) ? resp.msg : "delete error"});
                        return
                    }
                    setTip({open: true, severity: 'success', msg: 'delete success'});
        
                    let rs = [];
                    rows.forEach((v, i) => {
                        const ID = v.ID;
                        let ok = false;
                        selectedKeys.every((ID2) => {
                            if (ID === ID2) {
                                ok = true;
                                return false;
                            }
        
                            return true;
                        });
                        if (!ok) {
                            rs.push(v);
                        } 
                    });
                    setRows(rs);
                    setRowTotal(rowTotal - selectedKeys.length);
                    setSelectedKeys([]);
                } catch(e) {
                    console.log("[DistributorManagement]", e);
        
                    setTip({open:true, severity:"error", msg: "delete error"});
                }
            },
            onCancel() {
                //
            },
        });
    }

    const handleAPPKeyShow = (DistributorID) => {
        //
    }


    return (
        <div style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            padding: 8,
        }}>
            <div style={{
                flex: "0 1 auto",
            }}>
                <Form 
                    form={form}
                    initialValues={{
                        Categories: ['Manufacturer', "Distributor", 'Installer'],  
                        Enabled: ["Enabled", 'Disabled'],
                    }}
                    style={{
                        padding: 24,
                        background: '#fbfbfb',
                        border: '1px solid #d9d9d9',
                        borderRadius: 2
                    }}
                >
                    <Row gutter={24}>
                        <Col span={8} key="Categories">
                            <Form.Item 
                                name="Categories"
                                label=""
                            >
                                <Checkbox.Group options={['Manufacturer', "Distributor", 'Installer']} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key="DistributorName" >
                            <Form.Item 
                                name="DistributorName"
                                label="Distributor Name"
                            >
                                <Input placeholder="Enter Distributor Name" onPressEnter={handleSearch}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} key="Enabled" >
                            <Form.Item 
                                name="Enabled"
                                label=""
                            >
                                <Checkbox.Group options={["Enabled", 'Disabled']} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={handleSearch}>
                                Search
                            </Button>
                            <Button
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: 8,
                paddingBottom: 8,
            }}>
                <Button onClick={handleAdd} type="primary"  icon={<PlusOutlined /> } size="middle" >Add Distributor</Button>
                <div style={{flex: "0 0 10px"}}></div>
                <Button onClick={handleDeleteAllOfSelected} disabled={selectedKeys.length===0} type="primary"  icon={<DeleteOutlined />} size="middle" style={{marginLeft: 5}}/>
            </div>
            <div style={{
                flex:"1 1 1px",
                position: "relative",
            }}>
                <div ref={refTableContainer} style={{position: "absolute", height: '100%', width: "100%", overflow: "hidden"}}>
                    <Table 
                        columns={[{
                            title: "",
                            width: 70,
                            fixed: 'left',
                            render: (v, r, idx) => {
                                return (pageNo*pageSize+idx+1);
                            }
                        },{
                            title: "Name",
                            dataIndex: "Name",
                            width: 200,
                            fixed: 'left',
                        },{
                            title: "Superior",
                            dataIndex: "PName",
                            width: 200,
                        },{
                            title: "Category",
                            dataIndex: "Category",
                            width: 110,
                            render: (v, r, idx) => {
                                if (v === "0") {
                                    return "Distributor"
                                } else if (v === '1') {
                                    return "Installer";
                                } else if (v === '2') {
                                    return "Manufacturer";
                                }

                                return "";
                            }
                        },{
                            title: "Enabled",
                            dataIndex: "Enabled",
                            width: 80,
                            render: (v, r, idx) => {
                                return v === 1 || v === "1" ? (<CheckOutlined style={{color: "blue"}}/>) 
                                    : (<CloseOutlined style={{color: "#ad2102"}} />)
                            }
                        },{
                            title: "Address",
                            dataIndex: "Address",
                            width: 200,
                        },{
                            title: "Phone",
                            dataIndex: "Phone",
                            width: 150,
                        },{
                            title: "EMail",
                            dataIndex: "EMail",
                            width: 150,
                        },{
                            title: "GPS",
                            width: 200,
                            render: (v, r, idx) => {
                                if (! r["Longitude"] && ! r["Latitude"]) {
                                    return ""
                                }

                                return `(${r["Longitude"]!==null?(r["Longitude"]/1000000).toFixed(6):"-"}, ${r["Latitude"]!==null?(r["Latitude"]/1000000).toFixed(6):"-"})`
                            }
                        },{
                            title: "Timezone",
                            dataIndex: "Timezone",
                            width: 250,
                            render: (v, r, idx) => {
                                var n = parseInt(r["GmtOffset"]);
                                var tz = "UTC+";
                                if (n < 0) {
                                    tz = "UTC-";
                                    n = Math.abs(n);
                                }
                                tz += Math.floor(n / 3600).toString().padStart(2, "0") + ":" + Math.floor((n % 3600) / 60).toString().padStart(2, "0")
                                tz +="("+v+")"
                                return tz;
                            }
                        },{
                            title: "APP Key",
                            width: 80,
                            render: (v, r, idx) => {
                                return (<Tooltip title="Contact your system administrator" color="#87d068">
                                    <Button type="link" onClick={()=>{handleAPPKeyShow(r.ID)}}>To Show</Button>
                                </Tooltip>);
                            }
                        },{
                            title: "Main Account",
                            dataIndex: "MainAccountID",
                            width: 110,
                            render: (v, r, idx) => {
                                return v || "";
                            }
                        },{
                            title: "Create Time(UTC+0)",
                            dataIndex: 'CreateTime',
                            width: 150,
                        },{
                            title: 'Action',
                            key: 'Action',
                            width: 90,
                            render: renderAction,
                        }]}
                        pagination={{
                            pageSizeOptions: [25,50,100],
                            current: pageNo+1,
                            pageSize: pageSize,
                            total: rowTotal,
                            showSizeChanger: true,
                            showTotal: (total) => {return `Total ${total}`},
                            onShowSizeChange: (current, size) => {
                                setPageSize(size);
                            },
                            onChange: (page, pageSize) => {
                                setPageNo(page-1);                                
                            }
                        }}
                        tableLayout="fixed"
                        bordered={true}
                        size="middle"
                        loading={loading}
                        rowKey="ID"
                        dataSource={rows}
                        scroll={{ x: 1500, y: tableBodyHeight }}
                        rowSelection={{
                            selectedRowKeys: selectedKeys,
                            onChange: handleSelect,
                            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE]
                        }}
                    />
                </div>
            </div>
            {showAdder===true ? <Adder show={showAdder} onClose={handleAdderClose}/> : ""}
            {showModifier===true ? <Modifier data={rows[modifyRowIndex]} show={showModifier} onClose={handleModifierClose}/> : ""}
        </div>
    );
}


export default DistributorManagement;